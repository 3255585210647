import { getServerTime } from '@shm/interface';
import { MagicItemInstanceStatus } from '@shm/interface/lib/common/magic_item_instance_status';
import { GetMagicItemInstanceDetails } from '@shm/interface/lib/magicitem/service';
import { GetUnionMembershipTask } from '@shm/interface/lib/quest/service';
import { useCallback, useState } from 'react';
/** 饿了么联合会员当前券任务 */
export const useEleUnionCurrentTask = () => {
    const [curTask, setCurTask] = useState();
    const refreshTask = useCallback(() => {
        return GetUnionMembershipTask({}).then((res) => {
            var _a, _b, _c;
            if (!((_c = (_b = (_a = res === null || res === void 0 ? void 0 : res.task) === null || _a === void 0 ? void 0 : _a.extra) === null || _b === void 0 ? void 0 : _b.subTasks) === null || _c === void 0 ? void 0 : _c.length))
                return Promise.resolve(undefined);
            const subTask = res.task.extra.subTasks;
            const now = getServerTime() * 0.001;
            let task;
            subTask.some((item) => {
                const isCurTask = item.startTime <= now && item.endTime >= now;
                if (isCurTask) {
                    task = item;
                }
                return isCurTask;
            });
            setCurTask(task);
            return task;
        });
    }, []);
    /** 获取任务的券使用状态 */
    const getSubTaskOrderStatus = useCallback((task) => {
        if (!task.magicItemBindID)
            return Promise.resolve(MagicItemInstanceStatus.MAGIC_ITEM_INSTANCE_STATUS_UNDEFINED);
        return GetMagicItemInstanceDetails({ magicItemInstanceID: task.magicItemBindID }).then((res) => {
            return res.userMagicItemInstance.status;
        });
    }, []);
    return { curTask, refreshTask, getSubTaskOrderStatus };
};
