/* eslint-disable max-lines-per-function */
import { Action, TaskStatus, TaskType, } from '@shm/interface/lib/quest/model';
import { FinishFreeBuyTask } from '@shm/interface/lib/quest/service';
import { getRouteURL, navigateTo, RouteNameEnum } from '@shm/interface/lib/web.app';
import { useCallback, useContext } from 'react';
import { Hud } from '../../../../../../../components/Hud';
import { useUserInfo } from '../../../../../../../service/platform/useUserInfo';
import { useMemberPay } from '../../../../../../membership/components/MemberPay/hooks/useMemberPay';
import { TaskPanelContext } from '../../TaskPanel/state';
import { LocalTaskType, TaskContext } from '../componentsV2/TaskStore';
import { todaySubTaskStatus } from './todaySubTaskStatus';
const takeOutTasks = [
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_ELEME,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN,
];
/** 跳转去做任务 */
const navigateToTaskRouter = (task) => {
    if (!(task === null || task === void 0 ? void 0 : task.link))
        return;
    navigateTo(task.link);
};
const onNavigateTo0YuanBuy = () => {
    navigateTo(getRouteURL({
        name: RouteNameEnum.ZERO_YUAN_PACKAGE_ALLOWANCE,
        params: {
            tab: 0,
        },
    }));
};
export const useTask = () => {
    var _a, _b, _c, _d;
    const { setShowTaskPop, setTaskRewardPopInfo, setShowTaskSignWatchVideoRewardPop } = useContext(TaskPanelContext);
    const { getTaskList, updateTask } = useContext(TaskContext);
    const pay = useMemberPay();
    const openRewardPop = useCallback((task) => {
        setShowTaskPop(false);
        setTaskRewardPopInfo(task);
    }, [setShowTaskPop, setTaskRewardPopInfo]);
    const finshTask = useCallback((taskType, action, subTaskID, cb) => {
        FinishFreeBuyTask({
            action,
            taskType,
            subTaskID,
        })
            .then((res) => {
            // 签到看激励视频任务
            if (taskType ===
                TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN_EXTRA_VIDEO_REWARD) {
                setShowTaskSignWatchVideoRewardPop(true);
                return;
            }
            // 刷新任务
            updateTask(res === null || res === void 0 ? void 0 : res.task);
            if (action === Action.ACTION_CLAIM)
                getTaskList();
            cb === null || cb === void 0 ? void 0 : cb(res === null || res === void 0 ? void 0 : res.task);
        })
            .catch(({ msg }) => {
            if (msg)
                Hud.info(msg);
        });
    }, [getTaskList, setShowTaskSignWatchVideoRewardPop, updateTask]);
    const doSignTask = useCallback((task) => {
        const { todaySubTask, todayIsGiven } = todaySubTaskStatus(task);
        if (todayIsGiven || !(todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.ID))
            return;
        finshTask(task.taskType, Action.ACTION_CLAIM, todaySubTask.ID, (newTask) => {
            openRewardPop(newTask);
        });
    }, [finshTask, openRewardPop]);
    const renewalTimes = ((_b = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo) === null || _b === void 0 ? void 0 : _b.renewalTimes) || 0;
    const maxRenewalTimesAllowed = ((_d = (_c = useUserInfo()) === null || _c === void 0 ? void 0 : _c.membershipInfo) === null || _d === void 0 ? void 0 : _d.maxRenewalTimesAllowed) || 0;
    const doRenewTask = useCallback(() => {
        // 是否达到最大续费次数
        if (renewalTimes >= maxRenewalTimesAllowed) {
            Hud.info('您的续费次数已达上限');
            return;
        }
        pay({ reportParams: { from: 'zero_yuan_buy_task_renew' } }, true);
    }, [maxRenewalTimesAllowed, pay, renewalTimes]);
    const taskDoingOpreate = useCallback((task) => {
        // 签到任务，领取子任务并领取奖励
        if (task.taskType === TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN) {
            doSignTask(task);
        }
        else {
            navigateToTaskRouter(task);
        }
    }, [doSignTask]);
    const doTask = useCallback((task) => {
        // 续费省钱卡任务
        if (task.taskType === LocalTaskType.RENEW) {
            doRenewTask();
            return;
        }
        // 话费补贴任务
        if (task.taskType === LocalTaskType.PHONE_CHARGE) {
            navigateToTaskRouter(task);
            return;
        }
        // 已领取过奖励的任务不处理
        if ((task === null || task === void 0 ? void 0 : task.taskStatus) === TaskStatus.TASK_STATUS_REWARD_GIVEN) {
            if (task.taskType === TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SHOPPING_GUIDE)
                navigateToTaskRouter(task);
        }
        // 未领取任务，先领取
        if (task.taskStatus === TaskStatus.TASK_STATUS_UNDEFINED) {
            finshTask(task.taskType, Action.ACTION_ACCEPT, '', () => {
                taskDoingOpreate(task);
                if (takeOutTasks.includes(task.taskType))
                    getTaskList();
            });
        }
        // 任务进行中
        if ((task === null || task === void 0 ? void 0 : task.taskStatus) === TaskStatus.TASK_STATUS_AVAILABLE) {
            taskDoingOpreate(task);
        }
        // 订单任务: 拉到订单(待到账状态下，统一跳转到0元包邮补贴列表页)
        if ((task === null || task === void 0 ? void 0 : task.taskStatus) === TaskStatus.TASK_STATUS_ACCEPTED) {
            onNavigateTo0YuanBuy();
        }
        // 任务完成，可领取奖励
        if ((task === null || task === void 0 ? void 0 : task.taskStatus) === TaskStatus.TASK_STATUS_COMPLETED) {
            finshTask(task.taskType, Action.ACTION_CLAIM, '', () => {
                openRewardPop(task);
            });
        }
    }, [doRenewTask, finshTask, getTaskList, openRewardPop, taskDoingOpreate]);
    const completeWatchVideoAdTask = useCallback((subTask) => {
        var _a;
        finshTask((_a = subTask === null || subTask === void 0 ? void 0 : subTask.nextOne) === null || _a === void 0 ? void 0 : _a.taskType, Action.ACTION_CLAIM, subTask === null || subTask === void 0 ? void 0 : subTask.ID);
    }, [finshTask]);
    return {
        doTask,
        completeWatchVideoAdTask,
    };
};
