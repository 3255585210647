import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useModuleVisible } from '../../../../serviceSaveMoneyCard/useModuleVisible';
import { FreeShop, FreeShopStatusEnum } from '../FreeShopV2';
import styles from './styles.css';
/** 零元购 - 补贴全额返 0元包邮 */
export const FreeShopBox = () => {
    const { ref } = useModuleVisible('全额返模块');
    return (_jsxs("div", Object.assign({ ref: ref, className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.titleContainer }, { children: [_jsxs("div", Object.assign({ className: styles.title }, { children: ["\u5168\u989D", _jsx("span", { children: "\u73B0\u91D1\u8FD4\u8FD8" })] })), _jsx("p", { children: "\u5FAE\u4FE1\u63D0\u73B0 \u975E\u6D88\u8D39\u5238" })] })), _jsx(FreeShop, { freeShopStatus: FreeShopStatusEnum.SHOP_LIST })] })));
};
FreeShopBox.displayName = 'FreeShopBox';
