import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetJDNewerShopItemList } from '../../../../hooks/useGetJDNewerShopItemList';
import styles from './styles.css';
export const JDShopList = () => {
    const list = useGetJDNewerShopItemList();
    return (_jsx("div", Object.assign({ className: styles.shopListCt }, { children: list.map((item, i) => {
            var _a;
            return (_jsxs("div", Object.assign({ className: styles.shopItem }, { children: [_jsx("img", { src: item.base.imageURL, alt: "" }), _jsx("span", Object.assign({ className: styles.titleTxt }, { children: ((_a = item.base) === null || _a === void 0 ? void 0 : _a.shortTitle) || item.base.title })), _jsx("span", Object.assign({ className: styles.priceTxt }, { children: _jsxs("span", Object.assign({ className: styles.priceSubTxt1 }, { children: ["\uFFE5", _jsx("span", Object.assign({ className: styles.priceSubTxt2 }, { children: "0" }))] })) }))] }), i));
        }) })));
};
JDShopList.displayName = 'JDShopList';
