import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './styles.css';
/** 滑动块控制点 */
export const SliderControl = ({ count, selectedIndex, }) => {
    const list = useMemo(() => {
        const newArr = new Array(count).fill(0);
        return newArr.map((_, index) => {
            return (_jsx("div", { className: classNames(styles.item, {
                    [styles.itemSelected]: selectedIndex === index,
                }) }, index));
        });
    }, [count, selectedIndex]);
    return _jsx("div", Object.assign({ className: styles.container }, { children: list }));
};
SliderControl.displayName = 'SliderControl';
