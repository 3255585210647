import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SaveMoneyCardTaskCategory, TaskStatus, } from '@shm/interface/lib/quest/model';
import { ListSaveMoneyCardRedPacket } from '@shm/interface/lib/quest/service';
import { ReportMembershipScreenMemberRedPackageClick } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { navigateTo, RouteNameEnum } from '@shm/interface/lib/web.app';
import { formatMoney } from '@shm/item';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { webpImage } from '../../../../../../service/images';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import styles from './styles.css';
const from = getURLParamFrom();
const OrderItem = ({ info }) => {
    const order = info.orderDetail;
    const isGiven = info.taskDetail.taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN;
    const desc = useMemo(() => {
        return isGiven ? '已到账' : '待到账';
    }, [isGiven]);
    const statusDesc = useMemo(() => {
        return isGiven ? null : ``;
    }, [isGiven]);
    const onClick = useCallback(() => {
        // 5089_1_2043
        ReportMembershipScreenMemberRedPackageClick({
            title: info.taskDetail.category ===
                SaveMoneyCardTaskCategory.SAVE_MONEY_CARD_TASK_CATEGORY_SHOPPING
                ? '购物现金红包'
                : '外卖生活红包',
            from,
            type: '1',
            name: 'orderBar',
        });
        navigateTo({
            name: RouteNameEnum.SAVE_MONEY_RED_PACKET,
            params: {
                category: info.taskDetail.category,
                from: 'member-mall',
                header: 0,
            },
        });
    }, [info.taskDetail.category]);
    if (!order)
        return null;
    return (_jsxs("div", Object.assign({ className: styles.orderContainer, "aria-hidden": true, onClick: onClick }, { children: [_jsx("img", { alt: "", src: webpImage((order === null || order === void 0 ? void 0 : order.imageURL) || '') }), _jsxs("div", Object.assign({ className: styles.order_content }, { children: [_jsx("p", Object.assign({ className: styles.order_title }, { children: order === null || order === void 0 ? void 0 : order.itemTitle })), _jsxs("div", Object.assign({ className: styles.order_status }, { children: [_jsx("div", Object.assign({ className: classNames(styles.order_status_badge, {
                                    [styles.order_status_badgeGain]: isGiven,
                                }) }, { children: desc })), statusDesc && _jsx("span", { children: statusDesc })] }))] })), _jsxs("p", Object.assign({ className: styles.amount }, { children: ["+", formatMoney(info.taskDetail.amount), "\u5143"] }))] })));
};
OrderItem.displayName = 'OrderItem';
export const ExtraCashbackOrderBar = () => {
    const [animateStyle, setAnimateStyle] = useState();
    const [list, setList] = useState([]);
    const refIndex = useRef(0);
    useEffect(() => {
        ListSaveMoneyCardRedPacket({
            cursor: undefined,
            size: 10,
        }).then((res) => {
            const trueList = res.list;
            if (trueList.length > 1) {
                trueList.push(trueList[0]);
            }
            setList(trueList);
        });
    }, []);
    const tick = useCallback(() => {
        const isLast = refIndex.current === list.length - 1;
        if (isLast) {
            refIndex.current = 1;
            setAnimateStyle({
                transition: 'none',
                transform: `translateY(0%)`,
            });
        }
        else {
            refIndex.current += 1;
        }
        setTimeout(() => {
            setAnimateStyle({
                transform: `translateY(${-refIndex.current * 100}%)`,
            });
        }, 100);
    }, [list.length]);
    useEffect(() => {
        if (list.length <= 1)
            return undefined;
        const timer = setInterval(tick, 3000);
        return () => {
            clearInterval(timer);
        };
    }, [list.length, tick]);
    if (!list.length)
        return null;
    return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsx("div", Object.assign({ style: animateStyle, className: styles.listContainer }, { children: list.map((item, idx) => {
                return _jsx(OrderItem, { info: item }, idx);
            }) })) })));
};
ExtraCashbackOrderBar.displayName = 'ExtraCashbackOrderBar';
