import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ZeroYuanBuyPreview } from '../../../../../../components/ZeroYuanBuyPreview';
import { useModuleVisible } from '../../../../serviceSaveMoneyCard/useModuleVisible';
import styles from './styles.css';
/** 零元购 - 补贴全额返 0元包邮 */
export const ZeroYuanBuyBox = () => {
    const { ref } = useModuleVisible('0元购模块');
    return (_jsxs("div", Object.assign({ ref: ref, id: "freeshop-box", className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.titleContainer }, { children: [_jsxs("div", Object.assign({ className: styles.title }, { children: ["\u5929\u5929", _jsx("span", { children: "0\u5143\u5305\u90AE" })] })), _jsx("p", { children: "\u6BCF\u5929\u4E701\u5355 \u5929\u5929\u4EAB0\u5143" })] })), _jsx(ZeroYuanBuyPreview, { showLines: 1 })] })));
};
ZeroYuanBuyBox.displayName = 'ZeroYuanBuyBox';
