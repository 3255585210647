import { jsx as _jsx } from "react/jsx-runtime";
import { isFunction } from 'lodash-es';
import { useCallback, useEffect, useRef, } from 'react';
var Dir;
(function (Dir) {
    Dir["LEFT"] = "left";
    Dir["RIGHT"] = "right";
    Dir["UP"] = "up";
    Dir["DOWN"] = "down";
})(Dir || (Dir = {}));
function adaptMouse(event) {
    return Object.assign(event, { touches: [{ clientX: event.pageX, clientY: event.pageY }] });
}
function getDirection(absX, absY, deltaX, deltaY) {
    if (absX > absY) {
        if (deltaX > 0) {
            return Dir.LEFT;
        }
        return Dir.RIGHT;
    }
    if (deltaY > 0) {
        return Dir.UP;
    }
    return Dir.DOWN;
}
export const Swipeable = ({ onSwiping, onSwipedLeft, onSwipedRight, onSwipedUp, onSwipedDown, trackMouse = true, children, }) => {
    const eventDataRef = useRef();
    const domRef = useRef();
    const handlerByDir = useCallback((dir) => ({
        [Dir.LEFT]: onSwipedLeft,
        [Dir.RIGHT]: onSwipedRight,
        [Dir.UP]: onSwipedUp,
        [Dir.DOWN]: onSwipedDown,
    }[dir]), [onSwipedDown, onSwipedLeft, onSwipedRight, onSwipedUp]);
    const handleSwipeStart = useCallback((event) => {
        if (event.touches === undefined || event.touches.length > 1) {
            return;
        }
        const { clientX, clientY } = event.touches[0];
        const xy = [clientX, clientY];
        eventDataRef.current = {
            event,
            xy,
            swiping: false,
        };
    }, []);
    const handleSwipeMove = useCallback((event) => {
        if (eventDataRef.current === undefined)
            return;
        const { xy } = eventDataRef.current;
        if (xy === undefined || event.touches === undefined || event.touches.length > 1) {
            return;
        }
        const { clientX, clientY } = event.touches[0];
        const [x, y] = [clientX, clientY];
        const deltaX = xy[0] - x;
        const deltaY = xy[1] - y;
        const absX = Math.abs(deltaX);
        const absY = Math.abs(deltaY);
        const dir = getDirection(absX, absY, deltaX, deltaY);
        const eventData = { event, absX, absY, deltaX, deltaY, dir };
        if (isFunction(onSwiping))
            onSwiping(eventData);
        let cancelablePageSwipe = false;
        if (isFunction(handlerByDir(dir))) {
            cancelablePageSwipe = true;
        }
        if (cancelablePageSwipe && event.cancelable && event.preventDefault)
            event.preventDefault();
        eventDataRef.current = Object.assign(Object.assign({}, eventData), { xy, swiping: true });
    }, [handlerByDir, onSwiping]);
    const handleSwipeEnd = useCallback((event) => {
        if (eventDataRef.current === undefined)
            return;
        const { dir, swiping } = eventDataRef.current;
        const handler = dir !== undefined ? handlerByDir(dir) : null;
        if (swiping && isFunction(handler)) {
            handler(Object.assign(Object.assign({}, eventDataRef.current), { event }));
        }
        eventDataRef.current = Object.assign(Object.assign({}, eventDataRef.current), { event });
    }, [handlerByDir]);
    const handleMouseDown = useCallback((e) => handleSwipeStart(adaptMouse(e)), [handleSwipeStart]);
    const handleMouseUp = useCallback((e) => handleSwipeEnd(adaptMouse(e)), [handleSwipeEnd]);
    const handleMouseLevave = useCallback((e) => handleSwipeEnd(adaptMouse(e)), [handleSwipeEnd]);
    const handleMouseMove = useCallback((e) => handleSwipeMove(adaptMouse(e)), [handleSwipeMove]);
    useEffect(() => {
        const dom = domRef === null || domRef === void 0 ? void 0 : domRef.current;
        if (dom) {
            dom.addEventListener('touchstart', handleSwipeStart, {
                passive: false,
            });
            dom.addEventListener('touchmove', handleSwipeMove, {
                passive: false,
            });
            dom.addEventListener('touchend', handleSwipeEnd, {
                passive: false,
            });
            if (trackMouse) {
                dom.addEventListener('mousedown', handleMouseDown, {
                    passive: false,
                });
                dom.addEventListener('mouseup', handleMouseUp, {
                    passive: false,
                });
                dom.addEventListener('mouseleave', handleMouseLevave, {
                    passive: false,
                });
                dom.addEventListener('mousemove', handleMouseMove, {
                    passive: false,
                });
            }
        }
        return () => {
            if (dom) {
                dom.removeEventListener('touchstart', handleSwipeStart);
                dom.removeEventListener('touchmove', handleSwipeMove);
                dom.removeEventListener('touchend', handleSwipeEnd);
                if (trackMouse) {
                    dom.removeEventListener('mousedown', handleMouseDown);
                    dom.removeEventListener('mouseup', handleMouseUp);
                    dom.removeEventListener('mouseleave', handleMouseLevave);
                    dom.removeEventListener('mousemove', handleMouseMove);
                }
            }
        };
    }, [
        handleMouseDown,
        handleMouseLevave,
        handleMouseMove,
        handleMouseUp,
        handleSwipeEnd,
        handleSwipeMove,
        handleSwipeStart,
        trackMouse,
    ]);
    return (_jsx("div", Object.assign({ style: { touchAction: 'none' }, ref: domRef }, { children: children })));
};
Swipeable.displayName = 'Swipeable';
