import { Status } from '@shm/interface/lib/membership/model';
import { useMembershipInfo } from './useMembershipInfo';
/**
 * 用户是否开通会员
 * @returns boolean
 */
export const useIsOpenMember = () => {
    const membershipInfo = useMembershipInfo();
    return (membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.status) === Status.STATUS_OPENED;
};
