import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatMoney } from '@shm/interface';
import { ReportOneYuanBuyScreenPopupClick, ReportOneYuanBuyScreenPopupClose, ReportOneYuanBuyScreenPopupView, } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { Outside } from '../../../../../../components/Outside';
import { webpImage } from '../../../../../../service/images';
import { useDidFocus } from '../../../../../../service/useDidFocus';
import { todaySubTaskStatus } from '../Task/hooks/todaySubTaskStatus';
import { TaskPanelContext } from '../TaskPanel/state';
import closeImg from './assets/close.png';
import titleLightImg from './assets/title-light.png';
import styles from './styles.css';
export const TaskSignWatchVideoRewardPop = () => {
    var _a, _b, _c;
    const { setShowTaskPop, taskRewardPopInfo, setTaskRewardPopInfo, setShowTaskSignWatchVideoRewardPop, } = useContext(TaskPanelContext);
    const { todaySubTask } = todaySubTaskStatus(taskRewardPopInfo);
    const reportParams = useMemo(() => {
        var _a, _b;
        return {
            type: 'jlspreceive',
            task_type: `${(_a = todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.nextOne) === null || _a === void 0 ? void 0 : _a.taskType}`,
            name: '看视频翻倍',
            amount: `${(_b = todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.nextOne) === null || _b === void 0 ? void 0 : _b.rewardAmount}`,
        };
    }, [(_a = todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.nextOne) === null || _a === void 0 ? void 0 : _a.rewardAmount, (_b = todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.nextOne) === null || _b === void 0 ? void 0 : _b.taskType]);
    const onClose = useCallback(() => {
        ReportOneYuanBuyScreenPopupClose(reportParams);
        setShowTaskSignWatchVideoRewardPop(false);
        setTaskRewardPopInfo({});
        setShowTaskPop(true);
    }, [reportParams, setShowTaskPop, setShowTaskSignWatchVideoRewardPop, setTaskRewardPopInfo]);
    const onContinueClick = useCallback(() => {
        ReportOneYuanBuyScreenPopupClick(reportParams);
        setShowTaskSignWatchVideoRewardPop(false);
        setTaskRewardPopInfo({});
        setShowTaskPop(true);
    }, [reportParams, setShowTaskPop, setShowTaskSignWatchVideoRewardPop, setTaskRewardPopInfo]);
    const init = useRef(true);
    const reportView = useCallback(() => {
        if (!init.current)
            return;
        init.current = false;
        ReportOneYuanBuyScreenPopupView(reportParams);
    }, [reportParams]);
    useDidFocus(reportView);
    return (_jsx("div", Object.assign({ className: styles.mask }, { children: _jsx(Outside, Object.assign({ clickCallback: onClose }, { children: _jsxs(_Fragment, { children: [_jsx("img", { className: styles.titleImg, src: webpImage(titleLightImg), alt: "" }), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx("span", Object.assign({ className: styles.titleTxt }, { children: "\u606D\u559C\u83B7\u5F97\u8865\u8D34\u91D1\u7EA2\u5305" })), _jsxs("span", Object.assign({ className: styles.priceTxt }, { children: [formatMoney(((_c = todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.nextOne) === null || _c === void 0 ? void 0 : _c.rewardAmount) || 0), _jsx("span", { children: "\u5143" })] })), (todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.ID) !== '7' && (_jsx("span", Object.assign({ className: styles.descTxt }, { children: "\u660E\u65E5\u9886\u66F4\u591A\u54E6" }))), _jsx("button", Object.assign({ className: styles.okBtn, onClick: onContinueClick }, { children: "\u7EE7\u7EED\u8D5A\u8865\u8D34\u91D1" }))] })), _jsx("img", { "aria-hidden": true, onClick: onClose, className: styles.closeBtn, src: webpImage(closeImg), alt: "" })] }) })) })));
};
TaskSignWatchVideoRewardPop.displayName = 'TaskSignWatchVideoRewardPop';
