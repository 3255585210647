/** 分 转 两位 元 */
export const moneyFormat = (money, fixed) => {
    if (!money) {
        return 0;
    }
    if (fixed !== undefined) {
        return (Math.floor(money) / 100).toFixed(fixed);
    }
    return Math.floor(money) / 100;
};
/**
 * @param size
 * 根据设备的DPR，换算750设计稿的逻辑像素
 */
export const getSizeByDPR = (size) => {
    const docFontSize = parseFloat(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));
    return (size / 100) * docFontSize;
};
/** fuzzyUserName 模糊化处理用户名称 */
export const fuzzyUserName = (userName, placehold = '**') => {
    const len = userName.length;
    if (len < 2)
        return `${userName}${placehold}`;
    return `${userName[0]}${placehold}${userName[len - 1]}`;
};
