import { getAccountInfo } from '@shm/api/lib/trade';
import { AssetType } from '@shm/interface/lib/common/asset_type';
import { useCallback, useEffect, useState } from 'react';
import { useDidFocus } from '../../../../../service/useDidFocus';
import { useIsOpenMember } from '../../../../membership/components/MemberPay/hooks/useIsOpenMember';
export const useGetFund = () => {
    const [fund, setFund] = useState(0);
    const [used, setUsed] = useState(0);
    const initData = useCallback(() => {
        getAccountInfo().then((info) => {
            var _a, _b;
            if (info && info.assets) {
                const cashItem = info.assets.find((item) => item.asset_type === AssetType.ASSET_TYPE_MEMBERSHIP_0YUAN_BUY_FUND);
                if (cashItem) {
                    setFund(cashItem.balance);
                }
            }
            setUsed(((_b = (_a = info === null || info === void 0 ? void 0 : info.bonus) === null || _a === void 0 ? void 0 : _a.membership_1yuan_buy_fund_lijin_total_bonus) === null || _b === void 0 ? void 0 : _b.total_amount) || 0);
        });
    }, []);
    // 拿0元补贴余额和已使用金额
    useDidFocus(initData, true);
    const isOpenMember = useIsOpenMember();
    useEffect(() => {
        if (isOpenMember) {
            initData();
        }
    }, [isOpenMember, initData]);
    return [fund, used];
};
