import { jsx as _jsx } from "react/jsx-runtime";
import { getServerTime } from '@shm/interface';
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { GetOpenMembershipTask } from '@shm/interface/lib/quest/service';
import { useCallback, useEffect, useState } from 'react';
import { Mask } from '../../../../../../components/Mask';
import { Outside } from '../../../../../../components/Outside';
import { judgeBigScreen, rem2px } from '../../../../../../service/browser';
import { useLocalStorage } from '../../../../../../service/useLocalStorage';
import styles from './styles.css';
const OpenMemberAllReturnNewbiePopup = ({ hide, }) => {
    return (_jsx(Mask, Object.assign({ position: "center" }, { children: _jsx(Outside, Object.assign({ clickCallback: hide }, { children: _jsx("div", { "aria-hidden": true, onClick: hide, className: styles.container }) })) })));
};
OpenMemberAllReturnNewbiePopup.displayName = 'OpenMemberAllReturnNewbiePopup';
/** 全额返开通会员，成功后的首次引导弹窗 */
export const OpenMemberAllReturnNewbieApp = ({ userInfo, }) => {
    var _a;
    const [showPopup, setShowPopup] = useState(false);
    const [localOpenStatus, setLocalOpenStatus] = useLocalStorage(`${userInfo.ID}-openMember_by_allreturn_newbie_popup`, 0);
    const hide = useCallback(() => {
        setShowPopup(false);
    }, []);
    /** 检查是否是签到全额返类型 */
    const checkIsSignOpenMemberAllReturn = useCallback(() => {
        return GetOpenMembershipTask({}).then((res) => {
            var _a, _b;
            return !!((_a = res.task) === null || _a === void 0 ? void 0 : _a.ID) && ((_b = res.task) === null || _b === void 0 ? void 0 : _b.taskStatus) !== TaskStatus.TASK_STATUS_INVALID;
        });
    }, []);
    useEffect(() => {
        var _a;
        // 检测首次开通条件：开通时间30秒内
        if (!localOpenStatus &&
            getServerTime() * 0.001 - (((_a = userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.openedTime) || 0) < 30) {
            window.scrollTo(0, rem2px(judgeBigScreen() ? 1.6 : 2.7));
            setLocalOpenStatus(1);
            checkIsSignOpenMemberAllReturn().then((isSign) => {
                setShowPopup(isSign);
            });
        }
    }, [
        checkIsSignOpenMemberAllReturn,
        localOpenStatus,
        setLocalOpenStatus,
        (_a = userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.openedTime,
    ]);
    return showPopup ? _jsx(OpenMemberAllReturnNewbiePopup, { hide: hide }) : null;
};
OpenMemberAllReturnNewbieApp.displayName = 'OpenMemberAllReturnNewbieApp';
