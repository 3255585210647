import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AssetType } from '@shm/interface/lib/common/asset_type';
import { ReportMembershipScreenMemberRedPackageClick, ReportMembershipScreenMemberRedPackageView, } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { navigateTo, RouteNameEnum } from '@shm/interface/lib/web.app';
import { useCallback, useContext, useEffect } from 'react';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import { ExtraCashbackOrderBar } from '../ExtraCashbackOrderBar';
import { ExtraCashbackContext } from '../ExtraCashbackStore';
import styles from './styles.css';
const from = getURLParamFrom();
/**
 * 省钱卡 - 全年额外返
 */
const RedPackageItem = ({ title, name, count, unit, onClick }) => {
    useEffect(() => {
        // 5089_0_2042
        ReportMembershipScreenMemberRedPackageView({
            from,
            type: '1',
            title: name,
        });
    }, [name]);
    return (_jsxs("div", Object.assign({ className: styles.itemContainer, "aria-hidden": true, onClick: onClick }, { children: [_jsx("p", Object.assign({ className: styles.item_title }, { children: title })), _jsxs("p", Object.assign({ className: styles.item_count }, { children: [count, _jsx("span", { children: unit })] })), _jsx("p", Object.assign({ className: styles.item_name }, { children: name }))] })));
};
RedPackageItem.displayName = 'RedPackageItem';
export const ExtraCashBackBox = () => {
    var _a, _b;
    const { assetsInfo, numberOfUnusedCoupons } = useContext(ExtraCashbackContext);
    const cash = Math.max(0, ((_a = assetsInfo[AssetType.ASSET_TYPE_SHOPPING_RED_PACKET]) === null || _a === void 0 ? void 0 : _a.amount) || 0);
    const takeout = Math.max(0, ((_b = assetsInfo[AssetType.ASSET_TYPE_TAKEOUT_RED_PACKET]) === null || _b === void 0 ? void 0 : _b.amount) || 0);
    const onCashClick = useCallback(() => {
        ReportMembershipScreenMemberRedPackageClick({
            title: '购物现金红包',
            from,
            type: '1',
        });
        navigateTo({
            name: RouteNameEnum.SAVE_MONEY_RED_PACKET,
            params: { category: 1, header: 0 },
        });
    }, []);
    const onTakeoutClick = useCallback(() => {
        ReportMembershipScreenMemberRedPackageClick({
            title: '外卖生活红包',
            from,
            type: '1',
        });
        navigateTo({
            name: RouteNameEnum.SAVE_MONEY_RED_PACKET,
            params: { category: 2, header: 0 },
        });
    }, []);
    const onCouponClick = useCallback(() => {
        ReportMembershipScreenMemberRedPackageClick({
            title: '补贴红包',
            from,
            type: '1',
        });
        navigateTo({
            name: RouteNameEnum.SHOP_RED_PACKET,
            params: { from: 'member-mall', header: 0 },
        });
    }, []);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.titleContainer }, { children: [_jsxs("p", Object.assign({ className: styles.title }, { children: ["\u5168\u5E74\u989D\u5916\u53EF\u9886", _jsx("span", { children: "1008\u5143" }), "\u73B0\u91D1\u7EA2\u5305"] })), _jsx("p", { children: "\u7EA2\u5305\u53EF\u5FAE\u4FE1\u63D0\u73B0\uFF0C\u79D2\u5230\u8D26" })] })), _jsxs("div", Object.assign({ className: styles.listContainer }, { children: [_jsx(RedPackageItem, { title: "\u5269\u4F59", count: Math.max(0, Math.floor(cash * 0.01)), unit: "\u5143", name: "\u8D2D\u7269\u73B0\u91D1\u7EA2\u5305", onClick: onCashClick }), _jsx(RedPackageItem, { title: "\u5269\u4F59", count: Math.max(0, Math.floor(takeout * 0.01)), unit: "\u5143", name: "\u5916\u5356\u73B0\u91D1\u7EA2\u5305", onClick: onTakeoutClick }), _jsx(RedPackageItem, { title: "\u53EF\u7528", count: Math.max(0, numberOfUnusedCoupons), unit: "\u5F20", name: "\u4F18\u60E0\u5238", onClick: onCouponClick })] })), _jsx(ExtraCashbackOrderBar, {})] })));
};
ExtraCashBackBox.displayName = 'ExtraCashBackBox';
