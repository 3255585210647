import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// 点击了品牌
import { SPUType, SPUBuyPermission, TransformLinkType, } from '@shm/interface/lib/officialitem/model';
import { RouteNameEnum, navigateTo, getRouteURL } from '@shm/interface/lib/web.app';
import { useState, useCallback, useEffect } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { BuyMemberPop } from '../../../../components/BuyMemberPop/index';
import { Hud } from '../../../../components/Hud';
import { phoneChargeBrandID } from '../../services/interface';
import { hasMembershipPermission } from '../../services/service';
var ClickBrandErrorEnum;
(function (ClickBrandErrorEnum) {
    ClickBrandErrorEnum["NEED_USERINFO"] = "2";
    ClickBrandErrorEnum["NOT_MEMBERSHIP"] = "3";
})(ClickBrandErrorEnum || (ClickBrandErrorEnum = {}));
var DomShowTypeEnum;
(function (DomShowTypeEnum) {
    DomShowTypeEnum["MEMBER"] = "2";
    DomShowTypeEnum["USER_LOST"] = "3";
})(DomShowTypeEnum || (DomShowTypeEnum = {}));
let rootElement;
const getRootElement = () => {
    if (!rootElement) {
        rootElement = document.createElement('div');
    }
    if (rootElement) {
        document.querySelector('body').appendChild(rootElement);
    }
    return rootElement;
};
const destoryRootElement = () => {
    var _a;
    const root = getRootElement();
    if (root) {
        unmountComponentAtNode(root);
        (_a = root.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(root);
    }
};
const DomComponent = ({ type }) => {
    // 弹窗可见
    const [show, setShow] = useState({
        // 会员弹窗
        getMember: type === DomShowTypeEnum.MEMBER,
    });
    // 设置弹窗显示
    const onShow = useCallback((key, value) => {
        setShow((prev) => {
            return Object.assign(Object.assign({}, prev), { [key]: value });
        });
    }, []);
    useEffect(() => {
        let timeout = 0;
        if (type === DomShowTypeEnum.USER_LOST) {
            const hudClose = Hud.Text({
                text: '用户信息丢失',
            });
            timeout = window.setTimeout(() => {
                destoryRootElement();
                hudClose();
            }, 2000);
        }
        return () => {
            if (timeout) {
                window.clearTimeout(timeout);
            }
        };
    }, [type]);
    return (_jsx(_Fragment, { children: show.getMember && (_jsx(BuyMemberPop, { onClose: () => {
                onShow('getMember', false);
                destoryRootElement();
            } })) }));
};
DomComponent.displayName = 'DomComponent';
const renderApp = (type) => {
    render(_jsx(DomComponent, { type: type }), getRootElement());
};
export const ClickBrand = (brand, opt) => {
    return new Promise((resolve, reject) => {
        let link = '';
        const { type } = brand.attrs;
        const { from = '', userInfo } = opt || {};
        // 权益
        if (type === SPUType.SPU_TYPE_RIGHTS) {
            if (`${brand.ID}` === phoneChargeBrandID) {
                link = getRouteURL({
                    name: RouteNameEnum.MEMBER_MALL_BRAND,
                    params: {
                        id: String(brand.ID),
                        title: brand.title,
                        from,
                        header: 0,
                        bounces: 0,
                    },
                });
            }
            else {
                link = getRouteURL({
                    name: RouteNameEnum.MEMBER_MALL_BRAND,
                    params: {
                        id: String(brand.ID),
                        title: brand.title,
                        from,
                    },
                });
            }
        }
        // 卡券
        else if (type === SPUType.SPU_TYPE_COUPON) {
            link = getRouteURL({
                name: RouteNameEnum.MEMBER_COUPON_BRAND,
                params: {
                    id: String(brand.ID),
                    title: brand.title,
                    from,
                },
            });
        }
        // 第三方h5
        else if (type === SPUType.SPU_TYPE_THIRD_PARTY_H5) {
            link = brand.attrs.link;
            if (brand.attrs.permissions.includes(SPUBuyPermission.SPU_BUY_PERMISSION_MEMBERSHIP) &&
                !hasMembershipPermission(userInfo)) {
                // 必须会员购买
                renderApp(DomShowTypeEnum.MEMBER);
                reject(ClickBrandErrorEnum.NOT_MEMBERSHIP);
                return;
            }
            if (brand.attrs.transformLinkType === TransformLinkType.TRANSFORM_LINK_TYPE_TUANYOU) {
                if (link) {
                    if (userInfo) {
                        link = link
                            .replace('$userid$', userInfo.userNum)
                            .replace('[userNum]', userInfo.userNum);
                    }
                    else {
                        // 提示用户信息丢失
                        renderApp(DomShowTypeEnum.USER_LOST);
                        reject(ClickBrandErrorEnum.NEED_USERINFO);
                        return;
                    }
                }
            }
        }
        navigateTo(link);
        resolve();
    });
};
