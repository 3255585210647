import { MembershipPackageIsOpenReturn } from '@shm/interface/lib/membership/service';
import { useCallback, useState } from 'react';
import { useUserInfo } from '../../../../../service/platform/useUserInfo';
import { useDidFocus } from '../../../../../service/useDidFocus';
import { useLocalStorage } from '../../../../../service/useLocalStorage';
import { useOpenMemberPackage } from '../../../../membership/components/MemberPay/hooks/useOpenMemberPackage';
/**
 * 开通全返3.99组调整为直返
 * km: http://km.innotechx.com/pages/viewpage.action?pageId=213829768
 * 增加7.99元全返实验组
 * http://km.innotechx.com/pages/viewpage.action?pageId=216663260
 * @returns
 */
export const useCheck3dot99AbTest = () => {
    const memberPackage = useOpenMemberPackage();
    const user = useUserInfo();
    const [hasPoped, setPoped] = useLocalStorage(`open-save-money-card-abtest-popup-${user === null || user === void 0 ? void 0 : user.ID}`, false);
    const [showPopup, setShowPopup] = useState(false);
    const getReturnStatus = useCallback(() => {
        MembershipPackageIsOpenReturn({ packageID: memberPackage === null || memberPackage === void 0 ? void 0 : memberPackage.ID }).then((res) => {
            if (res.isOpenReturn && !hasPoped) {
                setShowPopup(true);
                setPoped(true);
            }
        });
    }, [hasPoped, memberPackage, setPoped]);
    useDidFocus(getReturnStatus);
    return { showPopup, setShowPopup };
};
