import { jsx as _jsx } from "react/jsx-runtime";
import { ReportMembershipScreenListItemClick, ReportMembershipScreenListItemView, ReportMembershipScreenTabClick, } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { useContext, useMemo } from 'react';
import { LifeRightsCategoryList } from '../../../../components/LifeRightsCategoryList';
import { StoreContext } from '../WithStore';
import { Banner } from './components/Banner';
/** 生活权益 特权模块组件 */
export const LifeRights = () => {
    const [{ lifeRightsHeight }] = useContext(StoreContext);
    const ceilingTop = useMemo(() => lifeRightsHeight, [lifeRightsHeight]);
    const ceilingHeight = useMemo(() => {
        return document.documentElement.clientHeight - lifeRightsHeight;
    }, [lifeRightsHeight]);
    const bannerNode = useMemo(() => {
        return _jsx(Banner, {});
    }, []);
    return (_jsx(LifeRightsCategoryList, { ceilingTop: ceilingTop, ceilingHeight: ceilingHeight, reportTabClick: ReportMembershipScreenTabClick, reportBrandItemView: ReportMembershipScreenListItemView, reportBrandItemClick: ReportMembershipScreenListItemClick, bannerComponent: bannerNode }));
};
LifeRights.displayName = 'LifeRights';
