import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatMoney } from '@shm/interface';
import { ReportOneYuanBuyScreenPopupClick, ReportOneYuanBuyScreenPopupClose, ReportOneYuanBuyScreenPopupView, } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Outside } from '../../../../../../components/Outside';
import { getTaskTitle } from '../Task/hooks/taskEventReportInfo';
import { TaskPanelContext } from '../TaskPanel/state';
import closeImg from './assets/close.png';
import lightImg from './assets/light.png';
import styles from './styles.css';
export const TaskRewardPop = () => {
    const { setShowTaskPop, taskRewardPopInfo, setTaskRewardPopInfo } = useContext(TaskPanelContext);
    const title = getTaskTitle(taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType);
    const reportParams = useMemo(() => {
        return {
            type: 'taskdone',
            task_type: `${taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType}`,
            name: title,
            amount: `${taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.rewardAmount}`,
        };
    }, [taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.rewardAmount, taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType, title]);
    const onClose = useCallback(() => {
        ReportOneYuanBuyScreenPopupClose(reportParams);
        init.current = false;
        setTaskRewardPopInfo({});
        setShowTaskPop(true);
    }, [reportParams, setShowTaskPop, setTaskRewardPopInfo]);
    const onShowTaskPopClick = useCallback(() => {
        ReportOneYuanBuyScreenPopupClick(reportParams);
        init.current = false;
        setTaskRewardPopInfo({});
        setShowTaskPop(true);
    }, [reportParams, setShowTaskPop, setTaskRewardPopInfo]);
    const init = useRef(false);
    const reportView = useCallback(() => {
        if (!(taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType))
            return;
        if (init.current)
            return;
        ReportOneYuanBuyScreenPopupView(reportParams);
        init.current = true;
    }, [reportParams, taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType]);
    useEffect(() => {
        reportView();
    }, [reportView]);
    if (!(taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType)) {
        init.current = false;
        return null;
    }
    return (_jsx("div", Object.assign({ className: styles.mask }, { children: _jsx(Outside, Object.assign({ clickCallback: onClose }, { children: _jsxs("div", Object.assign({ className: styles.popCt }, { children: [_jsx("img", { className: styles.lightImg, src: lightImg, alt: "" }), _jsxs("div", Object.assign({ className: styles.contentCt }, { children: [_jsx("span", Object.assign({ className: styles.titleTxt }, { children: title })), _jsxs("p", Object.assign({ className: styles.priceTxt }, { children: ["\uFFE5", _jsx("span", { children: formatMoney((taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.rewardAmount) || 0) })] })), _jsx("p", Object.assign({ className: styles.descTxt }, { children: "\u4EFB\u52A1\u5B8C\u6210 \u83B7\u5F97\u8865\u8D34\u91D1" })), _jsx("button", Object.assign({ onClick: onShowTaskPopClick, className: styles.btn }, { children: "\u7EE7\u7EED\u9886\u53D6\u5956\u52B1" })), _jsx("p", Object.assign({ className: styles.tipTxt }, { children: "\u5956\u52B1\u5DF2\u5B58\u51650\u5143\u8865\u8D34\u91D1\u989D\u5EA6" }))] })), _jsx("img", { className: styles.closeBtn, "aria-hidden": true, onClick: onClose, src: closeImg, alt: "" })] })) })) })));
};
TaskRewardPop.displayName = 'TaskRewardPop';
