import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Status } from '@shm/interface/lib/membership/model';
import { ReportMembershipScreenMemberRenewalPanelChargeClick } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { navigateTo, RouteNameEnum } from '@shm/interface/lib/web.app';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { webpImage } from '../../../../../../service/images';
import { useUserInfo } from '../../../../../../service/platform/useUserInfo';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import { useRenewMemberPackage } from '../../../../../membership/components/MemberPay/hooks/useRenewMemberPackage';
import { RestrictModal } from '../RestrictModal';
import styles from './styles.css';
const from = getURLParamFrom();
export const Card = () => {
    var _a;
    const userInfo = useUserInfo();
    const expiredTime = ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.expiredTime) || 0;
    const [showRestrictModal, setShowRestrictModal] = useState(false);
    const strDate = (userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo.status) === Status.STATUS_EXPIRED
        ? '已过期'
        : `${dayjs(expiredTime * 1000).format('YYYY-MM-DD')}到期`;
    const renewMemberPackage = useRenewMemberPackage();
    const onRenewClick = useCallback(() => {
        ReportMembershipScreenMemberRenewalPanelChargeClick({
            from,
            package_id: renewMemberPackage === null || renewMemberPackage === void 0 ? void 0 : renewMemberPackage.ID,
            name: renewMemberPackage === null || renewMemberPackage === void 0 ? void 0 : renewMemberPackage.name,
            position: 'top',
        });
        navigateTo({
            name: RouteNameEnum.MEMBERSHIP,
            params: {
                header: 0,
                bounces: 0,
                from: 'membership',
                show_open_quick_save_money_page: true,
            },
        });
    }, [renewMemberPackage === null || renewMemberPackage === void 0 ? void 0 : renewMemberPackage.ID, renewMemberPackage === null || renewMemberPackage === void 0 ? void 0 : renewMemberPackage.name]);
    const onCloseRestrictModal = useCallback(() => {
        setShowRestrictModal(false);
    }, []);
    if (!userInfo)
        return null;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.avatar }, { children: _jsx("img", { src: webpImage(userInfo.avatar), alt: "", className: styles.avatarPic }) })), _jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.userInfo }, { children: _jsx("span", Object.assign({ className: styles.userName }, { children: userInfo.nickname })) })), _jsx("div", Object.assign({ className: styles.number }, { children: strDate }))] }), _jsxs("button", Object.assign({ onClick: onRenewClick, className: styles.btnRenew }, { children: [_jsx("span", { children: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo.status) === Status.STATUS_EXPIRED
                            ? '开通极省卡'
                            : '升级极省卡' }), _jsx("span", { children: "\u4E13\u4EAB7\u5927\u7279\u6743" })] })), showRestrictModal && _jsx(RestrictModal, { onClose: onCloseRestrictModal })] })));
};
Card.displayName = 'Card';
