import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BatchGetCategoryWithSPUList, ListCategoryByCategoryID, } from '@shm/interface/lib/officialitem/service';
import cx from 'classnames';
import { debounce } from 'lodash-es';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { LazyImage } from '../../../../components/LazyImage';
import { domVisible, isPassive } from '../../../../service/browser';
import { webpImage } from '../../../../service/images';
import { useUserInfo } from '../../../../service/platform/useUserInfo';
import { getURLParamFrom } from '../../../../service/urlParams';
import { FromEnum } from '../../services/report';
import { ClickBrand } from '../ClickBrand';
import styles from './styles.css';
const PageContext = createContext({});
const from = getURLParamFrom();
const Title = () => {
    return _jsx("div", Object.assign({ className: styles.blockTitle }, { children: "\u7CBE\u9009\u6700\u706B\u5C0A\u8D35\u7279\u6743" }));
};
Title.displayName = 'Title';
// 自动滚动类目
const useScrollCategoryList = () => {
    const { categoryListBoxRef, categoryListRef } = useContext(PageContext);
    const onScrollCategoryList = useCallback((itemID) => {
        if (categoryListRef.current && categoryListBoxRef.current) {
            /** 单个分类的dom */
            const selectedCategoryItemDom = categoryListRef.current[itemID];
            const { height: categoryItemBoxHeight = 0 } = (selectedCategoryItemDom === null || selectedCategoryItemDom === void 0 ? void 0 : selectedCategoryItemDom.getBoundingClientRect()) || {};
            const { dataset: { itemIndex = 0 } = {} } = selectedCategoryItemDom || {};
            /** 包含所有分类项列表的盒子 */
            const categoryListBoxDom = categoryListBoxRef.current;
            const { height: categoryListBoxHeight = 0 } = (categoryListBoxDom === null || categoryListBoxDom === void 0 ? void 0 : categoryListBoxDom.getBoundingClientRect()) || {};
            // 可视区域能够展示item的数量
            const visibleItemNum = Math.ceil(categoryListBoxHeight / categoryItemBoxHeight);
            let scrollY = 0;
            /**
             * 滚动的计算方法是：
             * 当前的item序号大于可是区域能够展示的item数量的一半时
             * 就滚动超出部分的item数量的高度之和
             */
            if (itemIndex > visibleItemNum / 2) {
                scrollY =
                    Math.ceil(Number(itemIndex) - visibleItemNum / 2) * categoryItemBoxHeight;
            }
            try {
                categoryListBoxDom.scrollTo({ top: scrollY });
            }
            catch (e) {
                if (categoryListBoxDom.scrollTop) {
                    categoryListBoxDom.scrollTop = scrollY;
                }
            }
        }
    }, [categoryListBoxRef, categoryListRef]);
    return onScrollCategoryList;
};
/** 左侧 分类组件 */
const CategoryItem = () => {
    const { categoryList, selectedCategoryID, isCeiling } = useContext(PageContext);
    const { categoryListBoxRef, categoryListRef, brandBlockListRef, brandContentBoxRef } = useContext(PageContext);
    const { reportTabClick } = useContext(PageContext);
    const onScrollCategoryList = useScrollCategoryList();
    /** 点击左侧分类时 选中右侧对应的品牌模块 */
    const onSelectBrandBlock = useCallback((ID, name) => {
        var _a, _b, _c;
        if (brandBlockListRef.current) {
            const { scrollTop = 0 } = brandContentBoxRef.current || {};
            const { top: parentTop = 0 } = ((_a = brandContentBoxRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || {};
            const { top: targetBlockTop = 0 } = ((_b = brandBlockListRef.current[ID]) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) || {};
            /** 滚动偏移量 为的是能够保证 目标模块的上一个模块 能够完全离开可视区域 */
            const offsetTop = 10;
            const newTopValue = scrollTop + targetBlockTop - parentTop + offsetTop;
            try {
                (_c = brandContentBoxRef.current) === null || _c === void 0 ? void 0 : _c.scrollTo({ top: newTopValue });
            }
            catch (e) {
                if (brandContentBoxRef.current.scrollTop) {
                    brandContentBoxRef.current.scrollTop = newTopValue;
                }
            }
            onScrollCategoryList(ID);
            if (reportTabClick) {
                reportTabClick({ from, title: name });
            }
        }
    }, [brandBlockListRef, brandContentBoxRef, onScrollCategoryList, reportTabClick]);
    return useMemo(() => {
        return (_jsx("div", Object.assign({ className: cx(styles.leftPart, {
                [styles.leftPartHidden]: !isCeiling,
            }), ref: categoryListBoxRef }, { children: categoryList.map((item, index) => (_jsx("div", Object.assign({ "data-item-index": index, className: styles.categoryItemBox, ref: (node) => {
                    if (node) {
                        categoryListRef.current[item === null || item === void 0 ? void 0 : item.ID] = node;
                    }
                } }, { children: _jsx("div", Object.assign({ className: styles.categoryItemWrapper, onClick: () => onSelectBrandBlock(item.ID, item.name), "aria-hidden": true }, { children: _jsx("span", Object.assign({ className: cx(styles.categoryItem, {
                            [styles.selectedCategoryItem]: item.ID === selectedCategoryID,
                        }) }, { children: item.name })) })) }), item.ID))) })));
    }, [
        categoryList,
        categoryListBoxRef,
        categoryListRef,
        isCeiling,
        onSelectBrandBlock,
        selectedCategoryID,
    ]);
};
CategoryItem.displayName = 'CategoryItem';
/** 右侧 分类品牌模块组件 */
const BrandBlock = ({ item }) => {
    const { reportBrandItemView, reportBrandItemClick } = useContext(PageContext);
    const { spuList, category } = item;
    const userInfo = useUserInfo();
    const onBrandClick = useCallback((brand) => {
        if (reportBrandItemClick) {
            reportBrandItemClick({
                title: brand.title,
                brand_id: `${brand.ID}`,
                id: `${brand.ID}`,
                from,
            });
        }
        ClickBrand(brand, {
            from: FromEnum.MEMBERSHIP,
            userInfo,
        });
    }, [reportBrandItemClick, userInfo]);
    // 曝光
    const onBrandView = useCallback((dom, b) => {
        domVisible(dom, {
            key: `${window.location.href}/MEMBER_MALL/BRAND/VIEW/${category.ID}/${b.ID}`,
        })
            .then(() => {
            if (reportBrandItemView) {
                reportBrandItemView({
                    title: b.title,
                    brand_id: `${b.ID}`,
                    from,
                });
            }
        })
            .catch(() => {
            // nothing to do
        });
    }, [category.ID, reportBrandItemView]);
    return useMemo(() => {
        return (_jsxs("div", Object.assign({ className: styles.BrandBlockWrap }, { children: [_jsx("p", Object.assign({ className: styles.brandTitle }, { children: category === null || category === void 0 ? void 0 : category.name })), _jsx("div", Object.assign({ className: styles.brandListWrap }, { children: spuList.map((brandItem) => (_jsxs("div", Object.assign({ className: styles.brandListItem, onClick: () => onBrandClick(brandItem), "aria-hidden": true, ref: (e) => {
                            onBrandView(e, brandItem);
                        } }, { children: [brandItem.attrs.label && (_jsx("span", Object.assign({ className: styles.labelTag }, { children: brandItem.attrs.label }))), _jsx(LazyImage, { className: styles.brandItemImg, lazy: webpImage(brandItem.image) }), _jsx("span", Object.assign({ className: styles.brandItemDesc }, { children: brandItem.title }))] }), brandItem.ID))) }))] })));
    }, [category === null || category === void 0 ? void 0 : category.name, onBrandClick, onBrandView, spuList]);
};
BrandBlock.displayName = 'BrandBlock';
// 所有品牌列表
const BrandList = () => {
    const { categoryList, dispatchSelectedCategoryID, isCeiling, brandList } = useContext(PageContext);
    const { brandContentBoxRef, brandBlockListRef } = useContext(PageContext);
    const { bannerComponent } = useContext(PageContext);
    const onScrollCategoryList = useScrollCategoryList();
    const ioRef = useRef({});
    /** 当前在可视区域的模块节点列表 */
    const visibleNodeListRef = useRef([]);
    /** 通过比较可视区域所有模块中最小top值 即可得出可视区域最上方模块对应的目标分类 */
    const findSelectedCategoryByMinTop = useCallback(() => {
        var _a, _b;
        if (visibleNodeListRef.current.length === 0) {
            dispatchSelectedCategoryID((_a = categoryList[0]) === null || _a === void 0 ? void 0 : _a.ID);
        }
        let targetID = ((_b = visibleNodeListRef.current[0]) === null || _b === void 0 ? void 0 : _b.ID) || '';
        visibleNodeListRef.current.forEach((item) => {
            var _a, _b, _c, _d, _e, _f;
            const brandBlockRef = brandBlockListRef.current;
            const targetTop = (_c = (_b = (_a = brandBlockRef[targetID]) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect) === null || _b === void 0 ? void 0 : _b.call(_a)) === null || _c === void 0 ? void 0 : _c.top;
            const currentItemTop = (_f = (_e = (_d = brandBlockRef[item.ID]) === null || _d === void 0 ? void 0 : _d.getBoundingClientRect) === null || _e === void 0 ? void 0 : _e.call(_d)) === null || _f === void 0 ? void 0 : _f.top;
            if (currentItemTop < targetTop) {
                targetID = item.ID;
            }
        });
        dispatchSelectedCategoryID(String(targetID));
        onScrollCategoryList(String(targetID));
    }, [dispatchSelectedCategoryID, onScrollCategoryList, categoryList, brandBlockListRef]);
    useEffect(() => {
        const findSelectedCategoryByMinTopDebounce = debounce(findSelectedCategoryByMinTop, 60);
        ioRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const { itemId: itemID = 0, itemName } = entry.target.dataset;
                const { top = 0 } = entry.boundingClientRect || {};
                if (entry.isIntersecting) {
                    visibleNodeListRef.current = [
                        ...visibleNodeListRef.current,
                        { ID: itemID, top, name: itemName },
                    ];
                }
                else {
                    visibleNodeListRef.current = visibleNodeListRef.current.filter((preItem) => Number(preItem.ID) !== Number(itemID));
                }
                findSelectedCategoryByMinTopDebounce();
            });
        }, { root: brandContentBoxRef.current });
        return () => {
            ioRef.current.disconnect();
        };
    }, [brandContentBoxRef, findSelectedCategoryByMinTop]);
    return useMemo(() => {
        return (_jsxs("div", Object.assign({ className: cx(styles.rightPart, {
                [styles.rightPartHidden]: !isCeiling,
            }), ref: brandContentBoxRef }, { children: [bannerComponent, brandList.map((item) => {
                    var _a, _b, _c;
                    return (_jsx("div", Object.assign({ "data-item-id": (_a = item === null || item === void 0 ? void 0 : item.category) === null || _a === void 0 ? void 0 : _a.ID, "data-item-name": (_b = item === null || item === void 0 ? void 0 : item.category) === null || _b === void 0 ? void 0 : _b.name, ref: (node) => {
                            var _a;
                            if (node) {
                                ioRef.current.observe(node);
                                brandBlockListRef.current[(_a = item === null || item === void 0 ? void 0 : item.category) === null || _a === void 0 ? void 0 : _a.ID] = node;
                            }
                        } }, { children: _jsx(BrandBlock, { item: item }) }), (_c = item === null || item === void 0 ? void 0 : item.category) === null || _c === void 0 ? void 0 : _c.ID));
                })] })));
    }, [bannerComponent, brandBlockListRef, brandContentBoxRef, brandList, isCeiling]);
};
BrandList.displayName = 'BrandList';
// debounce吸顶
const useCeilingTop = () => {
    const { ceilingTop, dispatchIsCeiling, wrapperRef } = useContext(PageContext);
    const isCeilingHandle = useMemo(() => {
        return debounce(() => {
            const dom = wrapperRef.current;
            if (!dom) {
                return;
            }
            const rect = dom.getBoundingClientRect();
            dispatchIsCeiling(rect.top <= ceilingTop + 1);
        }, 100);
    }, [ceilingTop, dispatchIsCeiling, wrapperRef]);
    useEffect(() => {
        document.addEventListener('scroll', isCeilingHandle, isPassive()
            ? {
                passive: true,
            }
            : false);
        return () => {
            document.removeEventListener('scroll', isCeilingHandle);
        };
    }, [isCeilingHandle]);
};
// 初始化数据
const useGetDatas = () => {
    const { dispatchCategoryList, dispatchBrandList } = useContext(PageContext);
    const getDatas = useCallback(() => {
        ListCategoryByCategoryID({}).then((res) => {
            const { list = [] } = res;
            dispatchCategoryList(list);
            const ids = list.map((v) => {
                return v.ID;
            });
            BatchGetCategoryWithSPUList({
                categoryIDs: ids,
            }).then((res1) => {
                dispatchBrandList(res1.list || []);
            });
        });
    }, [dispatchBrandList, dispatchCategoryList]);
    useEffect(() => {
        getDatas();
    }, [getDatas]);
};
const Init = () => {
    useCeilingTop();
    useGetDatas();
    return _jsx(_Fragment, {});
};
Init.displayName = 'Init';
/** 生活权益 特权模块组件 */
export const LifeRightsCategoryList = ({ ceilingTop = 0, ceilingHeight = document.documentElement.clientHeight, reportTabClick, reportBrandItemView, reportBrandItemClick, bannerComponent, }) => {
    // 类目列表
    const [categoryList, dispatchCategoryList] = useState([]);
    // 品牌列表
    const [brandList, dispatchBrandList] = useState([]);
    /** 所选中的分类ID */
    const [selectedCategoryID, dispatchSelectedCategoryID] = useState('');
    // 是否已经吸顶
    const [isCeiling, dispatchIsCeiling] = useState(false);
    /** 包含左侧所有分类模块的节点 */
    const categoryListBoxRef = useRef({});
    /** 保存左侧所有分类节点的列表 */
    const categoryListRef = useRef({});
    /** 包含右侧所有品牌模块节点的列表 */
    const brandBlockListRef = useRef({});
    const brandContentBoxRef = useRef({});
    // 区域ref
    const wrapperRef = useRef({});
    return (_jsxs(PageContext.Provider, Object.assign({ value: {
            categoryList,
            dispatchCategoryList,
            selectedCategoryID,
            dispatchSelectedCategoryID,
            brandList,
            dispatchBrandList,
            isCeiling,
            dispatchIsCeiling,
            ceilingTop,
            categoryListBoxRef,
            categoryListRef,
            brandBlockListRef,
            brandContentBoxRef,
            wrapperRef,
            reportTabClick,
            reportBrandItemView,
            reportBrandItemClick,
            bannerComponent,
        } }, { children: [_jsx(Init, {}), _jsxs("div", Object.assign({ className: styles.lifeRightsCategoryListWrap, ref: wrapperRef, style: { height: ceilingHeight || document.documentElement.clientHeight } }, { children: [_jsx(Title, {}), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(CategoryItem, {}), _jsx(BrandList, {})] }))] }))] })));
};
LifeRightsCategoryList.displayName = 'LifeRightsCategoryList';
