import { jsx as _jsx } from "react/jsx-runtime";
import { GetSuccessOrderCountByActivityType } from '@shm/interface/lib/officialorder/service';
import { ReportOneYuanBuyScreenPopupView } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import { useEffect, useRef, useState } from 'react';
import { getReportParams } from '../../hooks/taskEventReportInfo';
import { BaseTaskItem } from '../BaseTaskItem';
import { TaskBtn } from '../TaskBtn';
import btnStyles from '../TaskBtn/styles.css';
export const PhoneChargeTaskItem = ({ task }) => {
    const [show, setShow] = useState(undefined);
    useEffect(() => {
        // 判断是否购买过话费补贴
        GetSuccessOrderCountByActivityType({}).then((res) => {
            setShow(!res.count);
        });
    }, [task === null || task === void 0 ? void 0 : task.taskStatus, task.taskType]);
    /** 话费补贴任务异步，这里补一下曝光埋点 */
    const phoneChargeReportView = useRef(false);
    useEffect(() => {
        if (show && !phoneChargeReportView.current) {
            phoneChargeReportView.current = true;
            ReportOneYuanBuyScreenPopupView(getReportParams(task));
        }
    }, [show, task]);
    if (!show)
        return null;
    return (_jsx(BaseTaskItem, Object.assign({ task: task }, { children: _jsx(TaskBtn, { classname: btnStyles.redBtn, btnText: '去购买', task: task }) })));
};
PhoneChargeTaskItem.displayName = 'PhoneChargeTaskItem';
