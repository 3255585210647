import { TaskStatus, TaskType } from '@shm/interface/lib/quest/model';
import { useEffect, useState } from 'react';
import redBtnStyles from '../componentsV2/TaskBtn/styles.css';
export const getBtnStyle = () => {
    return redBtnStyles;
};
export const useBtnText = (taskType, taskStatus) => {
    const [btnInfo, setBtnInfo] = useState({ btnText: '', btnStyle: '' });
    useEffect(() => {
        if (taskStatus === TaskStatus.TASK_STATUS_ACCEPTED)
            setBtnInfo({ btnText: '待到账', btnStyle: redBtnStyles.blankBtn });
        if (taskStatus === TaskStatus.TASK_STATUS_COMPLETED)
            setBtnInfo({ btnText: '领取奖励', btnStyle: redBtnStyles.yellowBtn });
        if (taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN)
            setBtnInfo({ btnText: '已完成', btnStyle: redBtnStyles.givenBtn });
        if (taskStatus === TaskStatus.TASK_STATUS_UNDEFINED ||
            taskStatus === TaskStatus.TASK_STATUS_AVAILABLE) {
            switch (taskType) {
                case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_VISITING_SECKILL:
                case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN_UNION_YOUXUAN:
                case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_JING_DONG_NEWBIE:
                    setBtnInfo({ btnText: '去逛逛', btnStyle: redBtnStyles.redBtn });
                    break;
                default:
                    setBtnInfo({ btnText: '去完成', btnStyle: redBtnStyles.redBtn });
                    break;
            }
        }
    }, [taskStatus, taskType]);
    return btnInfo;
};
