import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useMemo } from 'react';
import { useGetAds, useReportAdView } from '../../../../../../service/ads';
import { useModuleVisible } from '../../../../serviceSaveMoneyCard/useModuleVisible';
import styles from './styles.css';
export const BrandBox = () => {
    const brandRef = useRef({});
    const { ref } = useModuleVisible('会员权益-金岗位模块');
    const { ads, onAdClick } = useGetAds(['jingangwei_member_200'], true);
    const brandAdsList = useMemo(() => ads.slice(0, 10), [ads]);
    useReportAdView(brandAdsList, brandRef.current);
    if (brandAdsList.length < 5) {
        return null;
    }
    return (_jsxs("div", Object.assign({ ref: ref, className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.titleContainer }, { children: [_jsxs("p", Object.assign({ className: styles.title }, { children: [_jsx("span", { children: "92%" }), "\u7684\u7528\u6237\u5E38\u7528\u7684\u7701\u94B1\u65B9\u5F0F"] })), _jsx("p", { children: "\u5403\u559D\u73A9\u4E50\u7701\u4E0D\u505C" })] })), _jsx("div", Object.assign({ className: styles.listContainer }, { children: brandAdsList.map((item, index) => {
                    var _a;
                    return (_jsxs("div", Object.assign({ className: styles.item, onClick: () => onAdClick(item), "aria-hidden": true, ref: (r) => {
                            brandRef.current[item.ID] = r;
                        } }, { children: [_jsx("div", Object.assign({ className: styles.picture }, { children: _jsx("img", { src: (_a = item.image) === null || _a === void 0 ? void 0 : _a.imageURL, alt: "", className: styles.img }) })), _jsx("div", Object.assign({ className: styles.brandName }, { children: item.title.slice(0, 5) })), _jsx("div", Object.assign({ className: styles.discount }, { children: item.subtitle || ` ` }))] }), index));
                }) }))] })));
};
BrandBox.displayName = 'BrandBox';
