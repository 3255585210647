import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { formatMoney } from '@shm/interface';
import { Status } from '@shm/interface/lib/membership/model';
import { ReportOneYuanBuyScreenAccountDetailsEntryClick, ReportOneYuanBuyScreenMemberRenewalPanelChargeClick, ReportOneYuanBuyScreenMemberRenewalPanelView, ReportOneYuanBuyScreenOpenMemberGuideButtonClick, } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import { navigateTo, RouteNameEnum } from '@shm/interface/lib/web.app';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useUserInfo } from '../../../../../../../../service/platform/useUserInfo';
import { useEvent } from '../../../../../../../../service/useEvent';
import { useMemberPay } from '../../../../../../../membership/components/MemberPay/hooks/useMemberPay';
import { ZeroYuanBuyShopContext } from '../../../../context';
import { useGetFund } from '../../../../services/useGetFund';
import { UpdateTasksUnclickCountEvent, useGetAllTaskUnClickNum, } from '../../../Task/hooks/useGetAllTaskUnClickNum';
import styles from './styles.less';
const navigateToMembership = () => {
    navigateTo({
        name: RouteNameEnum.MEMBERSHIP,
        params: {
            header: 0,
            bounces: 0,
            from: 'save-money-zero-yuan-buy-top',
        },
    });
};
const navigateToOneYuanBuy = () => {
    navigateTo({
        name: RouteNameEnum.ZERO_YUAN_PACKAGE_ALLOWANCE,
        params: {
            tab: 2,
        },
    });
};
const Btn = () => {
    var _a;
    const membershipInfo = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo;
    const isExpire = (membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.status) === Status.STATUS_EXPIRED;
    const pay = useMemberPay();
    const onBtnClick = useCallback(() => {
        // 5090_1_2635
        ReportOneYuanBuyScreenOpenMemberGuideButtonClick({ from: 'top' });
        if (isExpire) {
            pay({ reportParams: { type: '20' } }, true);
        }
        else {
            navigateToMembership();
        }
    }, [isExpire, pay]);
    return (_jsxs("button", Object.assign({ onClick: onBtnClick, className: styles.openBtn }, { children: [isExpire ? '立即续费' : '立即开通', " >"] })));
};
Btn.displayName = 'Btn';
const RenewBar = () => {
    var _a;
    const membershipInfo = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo;
    const isExpire = (membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.status) === Status.STATUS_EXPIRED;
    const [fund, usedFund] = useGetFund();
    const { setShowTaskPop } = useContext(ZeroYuanBuyShopContext);
    const { unclickCount, getAllUnclickCount } = useGetAllTaskUnClickNum(true);
    const openAllowancePage = useCallback(() => {
        // 5090_1_1333
        ReportOneYuanBuyScreenAccountDetailsEntryClick({
            is_new: '1',
        });
        navigateToOneYuanBuy();
    }, []);
    const onShowTaskClick = useCallback(() => {
        ReportOneYuanBuyScreenMemberRenewalPanelChargeClick();
        setShowTaskPop(true);
    }, [setShowTaskPop]);
    useEvent(UpdateTasksUnclickCountEvent, getAllUnclickCount);
    return (_jsxs("div", Object.assign({ className: styles.openCt }, { children: [_jsxs("div", Object.assign({ className: styles.infoCt }, { children: [_jsxs("p", { children: [_jsx("span", Object.assign({ className: styles.moneyUnionTxt }, { children: "\uFFE5" })), _jsx("span", Object.assign({ className: styles.moneyTxt }, { children: formatMoney(fund) }))] }), _jsx("p", Object.assign({ "aria-hidden": true, onClick: openAllowancePage, className: styles.usedTxt }, { children: `已使用 ￥${formatMoney(usedFund)}` }))] })), isExpire ? (_jsx(Btn, {})) : (_jsxs("div", Object.assign({ className: styles.showTaskBtn }, { children: [_jsx("button", { onClick: onShowTaskClick }), unclickCount > 0 && _jsx("span", { children: unclickCount })] })))] })));
};
RenewBar.displayName = 'RenewBar';
/**
 * 会员信息条，展示0元补贴金额度
 */
export const MembershipBar = ({ inGuide }) => {
    var _a;
    const membershipInfo = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo;
    const isRenew = (membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.status) === Status.STATUS_OPENED ||
        (membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.status) === Status.STATUS_EXPIRED;
    const init = useRef(true);
    const reportView = useCallback(() => {
        if (!init.current)
            return;
        ReportOneYuanBuyScreenMemberRenewalPanelView();
        init.current = false;
    }, []);
    useEffect(() => {
        reportView();
    }, [reportView]);
    return (_jsx("div", Object.assign({ className: classNames(styles.infoBarCt, {
            [styles.tabTransparent]: inGuide,
        }) }, { children: _jsx("div", Object.assign({ className: styles.infoBar }, { children: _jsx("div", Object.assign({ className: styles.barBorder }, { children: isRenew ? (_jsx(RenewBar, {})) : (_jsx("div", Object.assign({ className: styles.notOpenCt }, { children: _jsx(Btn, {}) }))) })) })) })));
};
MembershipBar.displayName = 'MembershipBar';
