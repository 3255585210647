import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { flushPendingEvents } from '@shm/interface';
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { ReportOneYuanBuyScreenPopupClick, ReportOneYuanBuyScreenPopupView, } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { domVisible } from '../../../../../../../../service/browser';
import { webpImage } from '../../../../../../../../service/images';
import { TaskPanelContext } from '../../../TaskPanel/state';
import { getReportParams, RemoteTasks } from '../../hooks/taskEventReportInfo';
import { useTask } from '../../hooks/useTask';
import { TaskRewardText } from '../TaskRewardText';
import { LocalTaskType } from '../TaskStore';
import styles from './styles.css';
export const BaseTaskItem = ({ children, task }) => {
    const domRef = useRef(null);
    const { doTask } = useTask();
    const { setTaskClikedCache } = useContext(TaskPanelContext);
    const onTaskClick = useCallback(() => {
        ReportOneYuanBuyScreenPopupClick(getReportParams(task));
        flushPendingEvents();
        setTaskClikedCache(task.taskType);
        doTask(task);
    }, [task, setTaskClikedCache, doTask]);
    const initReport = useRef(false);
    useEffect(() => {
        // 话费补贴任务曝光埋点单独写，这里不上报
        if (task.taskType === LocalTaskType.PHONE_CHARGE)
            return;
        window.requestAnimationFrame(() => {
            if (!domRef.current)
                return;
            domVisible(domRef.current, {
                key: `${task === null || task === void 0 ? void 0 : task.taskType}_${Math.random()}`,
            }).then(() => {
                if (initReport.current)
                    return;
                initReport.current = true;
                ReportOneYuanBuyScreenPopupView(getReportParams(task));
            });
        });
    }, [task]);
    if ((RemoteTasks.includes(task.taskType) && !(task === null || task === void 0 ? void 0 : task.rewardAmount)) ||
        (task === null || task === void 0 ? void 0 : task.taskStatus) === TaskStatus.TASK_STATUS_INVALID) {
        return null;
    }
    return (_jsxs("div", Object.assign({ "aria-hidden": true, ref: domRef, className: styles.baseItemCt, onClick: onTaskClick }, { children: [_jsx("img", { className: styles.taskImg, src: webpImage((task === null || task === void 0 ? void 0 : task.taskImage) || ''), alt: "" }), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(TaskRewardText, { task: task }), children] }))] })));
};
BaseTaskItem.displayName = 'BaseTaskItem';
