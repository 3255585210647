import { jsx as _jsx } from "react/jsx-runtime";
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { ListFreeBuyTasksV2 } from '@shm/interface/lib/quest/service';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { onListAds } from '../../../../../../../../service/ads';
import { useUserInfo } from '../../../../../../../../service/platform/useUserInfo';
import { useDidFocus } from '../../../../../../../../service/useDidFocus';
export var LocalTaskType;
(function (LocalTaskType) {
    LocalTaskType[LocalTaskType["RENEW"] = 10000] = "RENEW";
    LocalTaskType[LocalTaskType["PHONE_CHARGE"] = 10001] = "PHONE_CHARGE";
})(LocalTaskType || (LocalTaskType = {}));
export const TaskContext = createContext({});
// 续费任务
const renewTask = { taskType: LocalTaskType.RENEW, rewardAmount: 2990 };
// 话费充值任务
const phoneTask = {
    taskType: LocalTaskType.PHONE_CHARGE,
    rewardAmount: 2990,
};
const sortList = (list, renewalTimes, maxRenewalTimesAllowed) => {
    list.sort((a, b) => {
        if ((a.taskType === LocalTaskType.RENEW || b.taskType === LocalTaskType.RENEW) &&
            renewalTimes >= maxRenewalTimesAllowed) {
            return a.taskType === LocalTaskType.RENEW ? 1 : -1;
        }
        if (a.taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN ||
            b.taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN) {
            return a.taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN ? 1 : -1;
        }
        return Number(b.order) - Number(a.order);
    });
    return list;
};
export const TaskStore = ({ children }) => {
    var _a, _b, _c, _d;
    const adTaskInfoList = useRef([]);
    const [tasks, setTasks] = useState([renewTask, phoneTask]);
    const renewalTimes = ((_b = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo) === null || _b === void 0 ? void 0 : _b.renewalTimes) || 0;
    const maxRenewalTimesAllowed = ((_d = (_c = useUserInfo()) === null || _c === void 0 ? void 0 : _c.membershipInfo) === null || _d === void 0 ? void 0 : _d.maxRenewalTimesAllowed) || 0;
    /** 根据taskType更新任务组数据 */
    const updateTasks = useCallback((values) => {
        const taskList = [];
        const newlist = values.concat([renewTask, phoneTask]);
        newlist.forEach((item) => {
            const taskAdInfo = adTaskInfoList.current.find((info) => info.taskType === item.taskType);
            if (!taskAdInfo)
                return;
            const newitem = Object.assign(Object.assign({}, taskAdInfo), item);
            taskList.push(newitem);
        });
        setTasks(sortList(taskList, renewalTimes, maxRenewalTimesAllowed));
    }, [adTaskInfoList, maxRenewalTimesAllowed, renewalTimes]);
    const getTaskList = useCallback(() => {
        ListFreeBuyTasksV2({}).then((res) => {
            updateTasks(res.tasks);
        });
    }, [updateTasks]);
    const updateTask = useCallback((v) => {
        setTasks((pre) => {
            return pre.map((item) => {
                if (item.taskType === (v === null || v === void 0 ? void 0 : v.taskType)) {
                    return Object.assign(Object.assign({}, item), v);
                }
                return item;
            });
        });
    }, []);
    // 从运营位拉取任务数据
    useEffect(() => {
        Promise.all([onListAds(['0yuan_task']), ListFreeBuyTasksV2({})]).then((res) => {
            const newTaskData = res[0]
                .filter((item) => {
                if (!(item === null || item === void 0 ? void 0 : item.ID))
                    return false;
                const taskType = Number(item.title);
                if (Number.isNaN(taskType) || taskType === 0)
                    return false;
                return true;
            })
                .map((item) => {
                var _a, _b;
                return {
                    taskImage: (_a = item.image) === null || _a === void 0 ? void 0 : _a.imageURL,
                    link: (_b = item.image) === null || _b === void 0 ? void 0 : _b.linkURL,
                    taskType: Number(item.title),
                    order: Number(item.order),
                };
            });
            adTaskInfoList.current = newTaskData;
            updateTasks(res[1].tasks);
        });
    }, [updateTasks]);
    // 拉取任务数据
    useDidFocus(getTaskList, false);
    return (_jsx(TaskContext.Provider, Object.assign({ value: {
            tasks,
            getTaskList,
            updateTask,
        } }, { children: children })));
};
TaskStore.displayName = 'TaskStore';
