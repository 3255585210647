// 广告
import { useIsMountedRef } from '@shm-open/utilities/lib/react';
import { getPlatformEnum } from '@shm/interface';
import { ListAds, ReportClickAd, ReportImpressionAd } from '@shm/interface/lib/ads/app/service';
import { AdPlatform } from '@shm/interface/lib/common/ad_platform';
import { AdType } from '@shm/interface/lib/common/ad_type';
import { ReportPopupAdClick, ReportPopupAdView, } from '@shm/interface/lib/reportevents/frontend/popup';
import { browser } from '@shm/interface/lib/web';
import { navigateTo, rpc } from '@shm/interface/lib/web.app';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { config } from '../config';
import { domVisible } from './browser';
const appVersion = browser.getSHMAppVersion();
const binaryVersion = browser.getSHMAppBinaryVersion();
// 获取渠道号
const getChannel = () => {
    if (browser.isSHMApp() && browser.isAndroid()) {
        return rpc
            .getCommonInfoV2()
            .then((commonInfo) => {
            return commonInfo.channel;
        })
            .catch(() => {
            return '';
        });
    }
    return Promise.resolve('');
};
/**
 * 读取广告
 * @version 16.5.0
 * @param position
 */
export const onListAds = (positions) => {
    return getChannel().then((channel) => {
        return ListAds({
            positions,
            platform: getPlatformEnum(),
            acceptAdPlatformAdTypes: [
                {
                    adPlatform: AdPlatform.AD_PLATFORM_UNDEFINED,
                    adTypes: [AdType.AD_TYPE_UNDEFINED],
                },
            ],
            version: {
                appVersion,
                binaryVersion,
            },
            channel,
            app: config.APP,
        }).then((res) => {
            return res.ads || [];
        });
    });
};
/**
 * 点击广告
 * @version 16.5.0
 * @param ad
 */
export const onClickAd = (ad) => {
    var _a;
    if (ad) {
        onReportAdClick(ad);
    }
    const link = ((_a = ad === null || ad === void 0 ? void 0 : ad.image) === null || _a === void 0 ? void 0 : _a.linkURL) || '';
    if (!link) {
        return;
    }
    navigateTo(link);
};
/**
 * 读取广告的hook，暴露处 第一条广告、所有广告、广告点击事件
 * @version 16.5.0
 * @param positions
 * @param effect
 * @returns
 */
export const useGetAds = (positions, 
// 是否在useEffect中读取
effect) => {
    const [datas, setDatas] = useState([]);
    const positionsRef = useRef(positions);
    const isMounted = useIsMountedRef();
    const onGetAds = useCallback(() => {
        onListAds(positionsRef.current).then((res) => {
            if (!isMounted.current)
                return;
            setDatas(res);
        });
    }, [isMounted]);
    useEffect(() => {
        if (effect) {
            onGetAds();
        }
    }, [effect, onGetAds]);
    return { firstAd: datas[0] || null, ads: datas, onGetAds, onAdClick: onClickAd };
};
/**
 * 广告行为 - 广告曝光
 * @param ads
 */
export const onReportAdView = (ads) => {
    const datas = Array.isArray(ads) ? ads : [ads];
    datas.forEach((ad) => {
        if (!(ad === null || ad === void 0 ? void 0 : ad.ID)) {
            return;
        }
        ReportPopupAdView({
            ad_id: ad.ID,
        });
        ReportImpressionAd({
            ad,
            platform: getPlatformEnum(),
        });
    });
};
/**
 * 广告行为 - 点击
 * @param ads
 */
export const onReportAdClick = (ads) => {
    const datas = Array.isArray(ads) ? ads : [ads];
    datas.forEach((ad) => {
        if (!(ad === null || ad === void 0 ? void 0 : ad.ID)) {
            return;
        }
        ReportPopupAdClick({
            ad_id: ad.ID,
        });
        ReportClickAd({
            ad,
            platform: getPlatformEnum(),
        });
    });
};
/**
 * 广告曝光hook
 * @param ads
 * @param domRef
 */
export const useReportAdView = (ads, adsDomRef) => {
    const adsRef = useRef([]);
    adsRef.current = ads;
    const adsLength = useMemo(() => (ads ? ads.filter(Boolean).length : 0), [ads]);
    useEffect(() => {
        if (adsLength > 0) {
            window.requestAnimationFrame(() => {
                adsRef.current.forEach((ad) => {
                    if (!ad) {
                        return;
                    }
                    const dom = adsDomRef === null || adsDomRef === void 0 ? void 0 : adsDomRef[ad.ID];
                    if (!dom) {
                        return;
                    }
                    domVisible(dom, {
                        key: `${window.location.href}/AD_VIEW/${ad.ID}`,
                    })
                        .then(() => {
                        onReportAdView(ad);
                    })
                        .catch(() => {
                        // nothing
                    });
                });
            });
        }
    }, [adsLength, adsDomRef]);
};
