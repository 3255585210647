import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useRef, useState, } from 'react';
export const PopUpContext = createContext({});
export const PopUpStore = ({ children }) => {
    const [popUpList, setPopUpList] = useState([]);
    const refPopUpGlobalID = useRef(0);
    const showPopUp = useCallback((componentType, props) => {
        refPopUpGlobalID.current += 1;
        const newPopUpVo = {
            componentType,
            props,
            popUpID: refPopUpGlobalID.current,
        };
        setPopUpList((old) => {
            return [...old, newPopUpVo];
        });
        return newPopUpVo.popUpID;
    }, []);
    const hidePopUp = useCallback((componentType) => {
        setPopUpList((old) => {
            const index = old.reverse().findIndex((item) => {
                if (typeof componentType === 'number') {
                    return item.popUpID === componentType;
                }
                return item.componentType === componentType;
            });
            if (index === -1)
                return old;
            old.splice(index, 1);
            old.reverse();
            return [...old];
        });
    }, []);
    const hasPopUp = useCallback((componentType) => {
        return popUpList.some((item) => {
            return item.componentType === componentType;
        });
    }, [popUpList]);
    return (_jsxs(PopUpContext.Provider, Object.assign({ value: { showPopUp, hidePopUp, hasPopUp } }, { children: [popUpList.map((item) => {
                return _jsx(item.componentType, Object.assign({}, item.props), item.popUpID);
            }), children] })));
};
PopUpStore.displayName = 'PopUpStore';
