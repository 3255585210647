import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TaskType } from '@shm/interface/lib/quest/model';
import { useCallback, useContext, useState } from 'react';
import { Task } from '../Task';
import { useGetAllTaskUnClickNum } from '../Task/hooks/useGetAllTaskUnClickNum';
import { useTaskAutoShow } from '../Task/hooks/useTaskAutoShow';
import { TaskRewardPop } from '../TaskRewardPop';
import { TaskSignRewardPop } from '../TaskSignRewardPop';
import { TaskSignWatchVideoRewardPop } from '../TaskSignWatchVideoRewardPop';
import { TaskPanelContext } from './state';
const Content = () => {
    const { taskRewardPopInfo, showTaskSignWatchVideoRewardPop } = useContext(TaskPanelContext);
    return (_jsxs(_Fragment, { children: [(taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType) === TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN ? (_jsx(TaskSignRewardPop, {})) : (_jsx(TaskRewardPop, {})), showTaskSignWatchVideoRewardPop && _jsx(TaskSignWatchVideoRewardPop, {})] }));
};
Content.displayName = 'Content';
const TaskPanel = ({ showTaskPop, taskUIStyle, onClose, setShowTaskPop, }) => {
    const { getCanShowGuide, saveShowGuideComplete } = useTaskAutoShow();
    const [refreshTasks, setRefreshTasks] = useState(false);
    const [taskRewardPopInfo, setTaskRewardPopInfo] = useState({});
    const [showTaskSignWatchVideoRewardPop, setShowTaskSignWatchVideoRewardPop] = useState(false);
    const { getTaskClikedCache, setTaskClikedCache } = useGetAllTaskUnClickNum();
    const full = taskUIStyle === 2 /* TASK_UI_STYLE_ENUM_FULL */;
    const onCloseHandle = useCallback(() => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    }, [onClose]);
    return (_jsxs(TaskPanelContext.Provider, Object.assign({ value: {
            setShowTaskPop,
            refreshTasks,
            setRefreshTasks,
            getCanShowGuide,
            saveShowGuideComplete,
            taskRewardPopInfo,
            setTaskRewardPopInfo,
            setTaskClikedCache,
            getTaskClikedCache,
            showTaskSignWatchVideoRewardPop,
            setShowTaskSignWatchVideoRewardPop,
        } }, { children: [(showTaskPop || full) && (_jsx(Task, { onCloseHandle: onCloseHandle, taskUIStyle: taskUIStyle })), _jsx(Content, {})] })));
};
TaskPanel.displayName = 'TaskPanel';
export { TaskPanel };
