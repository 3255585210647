import { Status } from '@shm/interface/lib/membership/model';
import { Status as OrderStatus } from '@shm/interface/lib/order/base/model';
// 是否有购买权限
export const hasMembershipPermission = (u) => {
    if (u && u.membershipInfo && u.membershipInfo.status === Status.STATUS_OPENED) {
        return true;
    }
    return false;
};
// 格式化品牌描述
export const formatBrandIntro = (intro) => {
    if (!intro) {
        return {
            label: intro,
        };
    }
    const m = intro.match(/^([\D]+)(\d?\.?\d?折)$/);
    if (!m) {
        return {
            label: intro,
        };
    }
    return {
        label: m[1],
        discount: m[2],
    };
};
// 格式化价格
export const formatAmount = (n) => {
    if (!n) {
        return 0;
    }
    return (n / 100).toFixed(2);
};
// 获取订单类型的文案
export const getOrderStatusText = (status) => {
    if (status === OrderStatus.STATUS_CREATED) {
        return '待支付';
    }
    if (status === OrderStatus.STATUS_PAID) {
        return '待发货';
    }
    if ([
        OrderStatus.STATUS_CONFIRMED,
        OrderStatus.STATUS_SETTLED,
        OrderStatus.STATUS_COMMISSION_RETURNED,
        OrderStatus.STATUS_SHIPPED,
        OrderStatus.STATUS_SIGNED,
    ].includes(status)) {
        return '已完成';
    }
    if (status === OrderStatus.STATUS_CANCELLED) {
        return '已退款';
    }
    if (status === OrderStatus.STATUS_UNDEFINED) {
        return '全部';
    }
    return '';
};
// 销量转万
export const formatSaleCount = (n) => {
    if (n >= 100000) {
        return '10w+';
    }
    if (n >= 10000) {
        return '1w+';
    }
    return `${n}`;
};
