import { GetActivityCategoryTree } from '@shm/interface/lib/bonus/app/service';
import { CategoryTag } from '@shm/interface/lib/catalog/base/model';
import { GetCategoryEntryListV4 } from '@shm/interface/lib/catalog/service';
import { EntryType } from '@shm/interface/lib/common/entry_type';
import { useEffect, useState } from 'react';
export const useGetJDNewerShopItemList = () => {
    const [itemList, setItemList] = useState([]);
    useEffect(() => {
        GetActivityCategoryTree({
            categoryTag: CategoryTag.CATEGORY_TAG_APP_ACTIVITY_JD_PULL_NEW,
        }).then((res) => {
            var _a, _b, _c;
            const subCategoryID = (_c = (_b = (_a = res.activityCategoryTree.children) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.activityCategoryInfo) === null || _c === void 0 ? void 0 : _c.categoryID;
            GetCategoryEntryListV4({
                categoryID: subCategoryID,
                cursor: '',
                size: 9,
            }).then((resp) => {
                const newList = [];
                if (resp.list) {
                    resp.list.forEach((el) => {
                        if (el.type === EntryType.ENTRY_TYPE_ITEM) {
                            if (newList.length < 3)
                                newList.push(el.item);
                        }
                    });
                    setItemList(newList);
                }
            });
        });
    }, []);
    return itemList;
};
