import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReportMembershipScreenMemberGuideMaskClick, ReportMembershipScreenMemberGuideMaskView, } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { Mask } from '../../../../../../components/Mask';
import { judgeBigScreen } from '../../../../../../service/browser';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import cardImg from './assets/card.png';
import cat from './assets/icon-cat.png';
import arrowIcon from './assets/yindaojiantou.png';
import styles from './styles.css';
const isBigScreen = judgeBigScreen();
const from = getURLParamFrom();
/** 饿了么联合会员运营位引导 */
export const EleUnionCardGuide = ({ hide, }) => {
    const onCloseClick = useCallback(() => {
        // 5089_1_2099
        ReportMembershipScreenMemberGuideMaskClick({ type: '0', from });
        hide();
    }, [hide]);
    useEffect(() => {
        // 5089_0_2098
        ReportMembershipScreenMemberGuideMaskView({
            type: '0',
            from,
        });
    }, []);
    return (_jsx(Mask, Object.assign({ position: "center" }, { children: _jsxs("div", Object.assign({ className: classNames(styles.container, {
                [styles.containerBigScreen]: isBigScreen,
            }) }, { children: [_jsxs("div", Object.assign({ className: styles.descGroup }, { children: [_jsx("img", { className: styles.cat, src: cat, alt: "" }), _jsxs("div", Object.assign({ className: styles.desc }, { children: [_jsx("p", { children: "\u606D\u559C\u5F00\u901A\u8054\u5408\u4F1A\u5458" }), _jsx("p", { children: "\u5728\u8FD9\u91CC\u9886\u53D6\u997F\u4E86\u4E48\u5403\u8D27\u5361\u54E6\uFF5E" })] }))] })), _jsx("img", { className: styles.arrowIcon, alt: "", src: arrowIcon }), _jsx("img", { className: styles.cardImg, src: cardImg, alt: "" }), _jsx("button", Object.assign({ onClick: onCloseClick, className: styles.btnIKnow }, { children: "\u6211\u77E5\u9053\u4E86" }))] })) })));
};
EleUnionCardGuide.displayName = 'EleUnionCardGuide';
