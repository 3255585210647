import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReportOneYuanBuyScreenTaskGuideView } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import classNames from 'classnames';
import { useCallback, useContext, useRef } from 'react';
import { judgeBigScreen } from '../../../../../../../../service/browser';
import { webpImage } from '../../../../../../../../service/images';
import { useDidFocus } from '../../../../../../../../service/useDidFocus';
import { ZeroYuanBuyShopContext } from '../../../../context';
import { MembershipBar } from '../../../Header/components/MembershipBar';
import redHand from './assets/red/guide-hand.png';
import styles from './styles.less';
/** 任务引导 */
export const Guide = () => {
    const init = useRef(true);
    const { tabSticky } = useContext(ZeroYuanBuyShopContext);
    const reportView = useCallback(() => {
        if (!init.current)
            return;
        ReportOneYuanBuyScreenTaskGuideView();
        init.current = false;
    }, []);
    useDidFocus(reportView);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, {
            [styles.containerLongscreen]: judgeBigScreen(),
        }) }, { children: [_jsx(MembershipBar, { inGuide: true }), _jsx("img", { className: classNames(styles.hand, {
                    [styles.stickyHand]: tabSticky,
                }), alt: "", src: webpImage(redHand) })] })));
};
Guide.displayName = 'Guide';
