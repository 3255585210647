// webview激活的时候触发
import { useRefWrapper } from '@shm-open/utilities/lib/react';
import { browser } from '@shm/interface/lib/web';
import { rpc } from '@shm/interface/lib/web.app';
import { useEffect, useCallback, useRef } from 'react';
const isInApp = browser.isSHMApp();
export const useDidFocus = (focusFn, init = true) => {
    const focusFnRef = useRefWrapper(focusFn);
    const isFirstFocusReceived = useRef(false);
    // web focus in visibility
    const webFocusProxy = useCallback(() => {
        var _a;
        if (document.visibilityState === 'visible') {
            (_a = focusFnRef.current) === null || _a === void 0 ? void 0 : _a.call(focusFnRef);
        }
    }, [focusFnRef]);
    // app focus
    const appFocusProxy = useCallback(() => {
        var _a;
        if (init || isFirstFocusReceived.current) {
            (_a = focusFnRef.current) === null || _a === void 0 ? void 0 : _a.call(focusFnRef);
        }
        isFirstFocusReceived.current = true;
    }, [focusFnRef, init]);
    useEffect(() => {
        if (isInApp) {
            rpc.addEventListener('focus', appFocusProxy);
        }
        else {
            document.addEventListener('visibilitychange', webFocusProxy);
        }
        return () => {
            if (isInApp) {
                rpc.removeEventListener('focus', appFocusProxy);
            }
            else {
                document.removeEventListener('visibilitychange', webFocusProxy);
            }
        };
    }, [appFocusProxy, webFocusProxy]);
    useEffect(() => {
        var _a;
        if (!isInApp && init && !isFirstFocusReceived.current) {
            (_a = focusFnRef.current) === null || _a === void 0 ? void 0 : _a.call(focusFnRef);
            isFirstFocusReceived.current = true;
        }
    }, [focusFnRef, init]);
};
