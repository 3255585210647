import { getServerTime } from '@shm/interface';
import { Status } from '@shm/interface/lib/membership/model';
import { useCallback, useEffect } from 'react';
import { useUserInfo } from '../../../../../../../service/platform/useUserInfo';
import { useLocalStorage } from '../../../../../../../service/useLocalStorage';
const getDay = () => {
    return new Date().toDateString();
};
const autoShowCacheKey = 'save-money-zero-yuan-buy-task-auto-show';
const clearLastDayAutoShowCache = (userID) => {
    if (!userID)
        return;
    const now = getServerTime();
    const lastDay = new Date(now - 24 * 3600000).toDateString();
    const lastDayCacheKey = `${autoShowCacheKey}-${userID}-${lastDay}`;
    window.localStorage.removeItem(lastDayCacheKey);
};
export const useTaskAutoShow = () => {
    var _a, _b;
    const userInfo = useUserInfo();
    const [localShowedStatus, setLocalShowedStatus] = useLocalStorage(`${autoShowCacheKey}-${userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID}-${getDay()}`, true);
    const [hasGuidePlayed, setGuidePlayed] = useLocalStorage(`save-money-zero-yuan-buy-task-guide-${userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID}`, false);
    /** 获取是否可以打开新手引导 */
    const getCanShowGuide = useCallback(() => {
        var _a;
        if (((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.status) === Status.STATUS_NOT_OPENED)
            return false;
        return !hasGuidePlayed;
    }, [hasGuidePlayed, (_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.status]);
    /** 保存已打开新手引导 */
    const saveShowGuideComplete = useCallback(() => {
        setGuidePlayed(true);
    }, [setGuidePlayed]);
    /** 获取是否可以自动打开任务面板 */
    const getCanAutoShow = useCallback(() => {
        var _a;
        return (!getCanShowGuide() &&
            localShowedStatus &&
            ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.status) === Status.STATUS_OPENED);
    }, [getCanShowGuide, localShowedStatus, (_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _b === void 0 ? void 0 : _b.status]);
    /** 保存已自动打开 */
    const saveAutoShowComplete = useCallback(() => {
        setLocalShowedStatus(false);
    }, [setLocalShowedStatus]);
    /** 删除上一天存入的缓存 */
    useEffect(() => {
        if (!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID))
            return;
        clearLastDayAutoShowCache((userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID) || '');
    }, [userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID]);
    return {
        getCanAutoShow,
        saveAutoShowComplete,
        getCanShowGuide,
        saveShowGuideComplete,
    };
};
