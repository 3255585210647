import { TaskStatus } from '@shm/interface/lib/quest/model';
export const todaySubTaskStatus = (task) => {
    var _a, _b;
    const todayStartStamp = Math.floor(new Date().setHours(0, 0, 0) * 0.001);
    const todayEndStamp = Math.floor(new Date().setHours(24, 0, 0) * 0.001);
    const todaySubTask = (_a = task === null || task === void 0 ? void 0 : task.subTasks) === null || _a === void 0 ? void 0 : _a.find((item) => item.startTime >= todayStartStamp && item.endTime <= todayEndStamp);
    const idx = (_b = task === null || task === void 0 ? void 0 : task.subTasks) === null || _b === void 0 ? void 0 : _b.findIndex((sub) => sub.ID === (todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.ID));
    return {
        todaySubTask,
        index: idx || 0,
        todayIsGiven: (todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.taskStatus) === TaskStatus.TASK_STATUS_REWARD_GIVEN || false,
    };
};
