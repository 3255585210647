import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TaskType } from '@shm/interface/lib/quest/model';
import { ReportOneYuanBuyScreenPopupClose, ReportOneYuanBuyScreenPopupView, ReportOneYuanBuyScreenTaskGuideClick, } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { Mask } from '../../../../../../components/Mask';
import { Outside } from '../../../../../../components/Outside';
import { judgeBigScreen } from '../../../../../../service/browser';
import { useUserInfo } from '../../../../../../service/platform/useUserInfo';
import { TaskPanelContext } from '../TaskPanel/state';
import redCloseImg from './assets/red/close.png';
import redpacketImg from './assets/red/red-packet.png';
import { Guide } from './components/Guide';
import { CommonTaskItem } from './componentsV2/CommonTaskItem';
import { JDNewerTaskItem } from './componentsV2/JDNewerTaskItem';
import { PhoneChargeTaskItem } from './componentsV2/PhoneChargeTaskItem';
import { RenewTaskItem } from './componentsV2/RenewTaskItem';
import { ShoppingTeachTaskItem } from './componentsV2/ShoppingTeachTaskItem';
import { SignTaskItem } from './componentsV2/SignTaskItem';
import { LocalTaskType, TaskContext, TaskStore } from './componentsV2/TaskStore';
import styles from './styles.css';
const useClose = (onCloseHandle) => {
    const { setShowTaskPop, getCanShowGuide, saveShowGuideComplete } = useContext(TaskPanelContext);
    const [showGuide, setShowGuide] = useState(getCanShowGuide());
    const onClose = useCallback(() => {
        if (showGuide) {
            ReportOneYuanBuyScreenTaskGuideClick();
            setShowGuide(false);
            saveShowGuideComplete();
            return;
        }
        ReportOneYuanBuyScreenPopupClose({ type: 'taskpopup' });
        setShowTaskPop(false);
        onCloseHandle === null || onCloseHandle === void 0 ? void 0 : onCloseHandle();
        if (getCanShowGuide()) {
            saveShowGuideComplete();
        }
    }, [getCanShowGuide, onCloseHandle, saveShowGuideComplete, setShowTaskPop, showGuide]);
    return { onClose, showGuide };
};
const getTaskItem = (task) => {
    switch (task.taskType) {
        case LocalTaskType.RENEW:
            return _jsx(RenewTaskItem, { task: task }, task.taskType);
        case LocalTaskType.PHONE_CHARGE:
            return _jsx(PhoneChargeTaskItem, { task: task }, task.taskType);
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN:
            return _jsx(SignTaskItem, { task: task }, task.taskType);
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SHOPPING_GUIDE:
            return _jsx(ShoppingTeachTaskItem, { task: task }, task.taskType);
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_JING_DONG_NEWBIE:
            return _jsx(JDNewerTaskItem, { task: task }, task.taskType);
        default:
            return _jsx(CommonTaskItem, { task: task }, task.taskType);
    }
};
const TaskTitleWrapper = ({ full, onClose, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.titleCt }, { children: [_jsx("img", { className: styles.titleRedPacketImg, src: redpacketImg, alt: "" }), _jsx("span", Object.assign({ className: styles.taskTitleTxt }, { children: "\u505A\u4EFB\u52A1\u9886\u8865\u8D34\u91D1" }))] })), !full && (_jsx("img", { "aria-hidden": true, onClick: onClose, className: styles.closeImg, src: redCloseImg, alt: "" }))] }));
};
TaskTitleWrapper.displayName = 'TaskTitleWrapper';
/**
 * 任务状态链
 *  逛秒杀和日常逛街
    UNDEFINED (去领任务) => AVAILABLE(已领取，待完成) => COMPLETED(完成任务,待领奖) => REWARD_GIVEN

    订单的状态任务的状态还和之前一样:
    UNDEFINED (去领任务) => AVAILABLE(去下单) => ACCEPTED(拉订单后) => COMPLETED(确认收货) => REWARD_GIVEN(点击领取奖励, 奖励发放实际上跟前端没有关系)

    定时领红包【0yuan_qiandao】
    主任务状态
    UNDEFINED (去领任务) => AVAILABLE(已领取，待完成) => REWARD_GIVEN
    子任务状态
    UNDEFINED (去领任务) => REWARD_GIVEN
    
 */
const TaskV2 = (props) => {
    const { taskUIStyle = 1 /* TASK_UI_STYLE_ENUM_NORMAL */, onCloseHandle } = props;
    const { tasks } = useContext(TaskContext);
    const { onClose, showGuide } = useClose(onCloseHandle);
    const init = useRef(true);
    const reportView = useCallback(() => {
        if (!init.current)
            return;
        ReportOneYuanBuyScreenPopupView({ type: 'taskpopup' });
        init.current = false;
    }, []);
    useEffect(() => {
        reportView();
    }, [reportView]);
    const full = taskUIStyle === 2 /* TASK_UI_STYLE_ENUM_FULL */;
    const isShowGuide = taskUIStyle === 1 /* TASK_UI_STYLE_ENUM_NORMAL */;
    const TaskContent = (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(styles.taskCt, {
                    [styles.taskCtFull]: full,
                }) }, { children: [_jsx(TaskTitleWrapper, { full: full, onClose: onClose }), _jsx("div", Object.assign({ className: classNames(styles.listCt, {
                            [styles.listCtLongScreen]: judgeBigScreen(),
                            [styles.full]: full,
                        }) }, { children: tasks.map((item) => {
                            return getTaskItem(item);
                        }) }))] })), showGuide && isShowGuide && _jsx(Guide, {})] }));
    return full ? (TaskContent) : (_jsx(Mask, { children: _jsx(Outside, Object.assign({ clickCallback: onClose }, { children: TaskContent })) }));
};
TaskV2.displayName = 'TaskV2';
export const Task = (props) => {
    const uer = useUserInfo();
    if (!(uer === null || uer === void 0 ? void 0 : uer.ID))
        return null;
    return (_jsx(TaskStore, { children: _jsx(TaskV2, Object.assign({}, props)) }));
};
Task.displayName = 'Task';
