import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatMoney } from '@shm/interface';
import { ReportMembershipScreenMemberRenewalPanelChargeClick } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { useCallback } from 'react';
import { useUserInfo } from '../../../../../../service/platform/useUserInfo';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import { useMemberPay } from '../../../../../membership/components/MemberPay/hooks/useMemberPay';
import { useRenewMemberPackage } from '../../../../../membership/components/MemberPay/hooks/useRenewMemberPackage';
import styles from './styles.css';
const from = getURLParamFrom();
export const RenewBottomBanner = () => {
    var _a;
    const membershipInfo = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo;
    const memberPackage = useRenewMemberPackage();
    const pay = useMemberPay();
    const onRenewClick = useCallback(() => {
        ReportMembershipScreenMemberRenewalPanelChargeClick({
            from,
            package_id: memberPackage === null || memberPackage === void 0 ? void 0 : memberPackage.ID,
            name: memberPackage === null || memberPackage === void 0 ? void 0 : memberPackage.name,
            position: 'bottom',
        });
        pay({ reportParams: { type: '23' } }, true);
    }, [memberPackage === null || memberPackage === void 0 ? void 0 : memberPackage.ID, memberPackage === null || memberPackage === void 0 ? void 0 : memberPackage.name, pay]);
    if (!(membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.nextPayIsFirstRenewal))
        return null;
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: styles.renewBannerCt }, { children: _jsxs("div", Object.assign({ className: styles.renewBottomBannerCt, onClick: onRenewClick, "aria-hidden": true }, { children: [_jsxs("span", Object.assign({ className: styles.descTxt }, { children: ["\u9650\u65F6", _jsx("span", { children: formatMoney((memberPackage === null || memberPackage === void 0 ? void 0 : memberPackage.subsidyAmount) || 0) }), "\u5143 \u5F00\u901A\u5373\u8D5A\u5230"] })), _jsx("div", Object.assign({ className: styles.tip }, { children: "\u4E0D\u56DE\u672C\u53EF\u5168\u989D\u9000\u5DEE\u4EF7" }))] })) })) }));
};
RenewBottomBanner.displayName = 'RenewBottomBanner';
