import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { Mask } from '../../../../components/Mask';
import { PopUpContext } from '../../../../components/PopUpStore';
import { webpImage } from '../../../../service/images';
import dialogBg from './assets/dialog-bg.png';
import lightCircle from './assets/light-circle.png';
import tipsBG from './assets/tips-bg.png';
import tipsContent from './assets/tips-content.png';
import styles from './styles.css';
export const BestLuckPopup = () => {
    const { hidePopUp } = useContext(PopUpContext);
    const close = useCallback(() => {
        hidePopUp(BestLuckPopup);
    }, [hidePopUp]);
    return (_jsx(Mask, Object.assign({ position: "center" }, { children: _jsx("div", Object.assign({ className: styles.root, onClick: close, "aria-hidden": true }, { children: _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx("img", { className: styles.light, src: webpImage(lightCircle), alt: '#' }), _jsx("img", { className: styles.img, src: dialogBg, alt: "#" }), _jsx("img", { className: styles.amountBg, src: tipsBG, alt: "#" }), _jsx("img", { className: styles.amount, src: tipsContent, alt: "#" })] })) })) })));
};
BestLuckPopup.displayName = 'BestLuckPopup';
