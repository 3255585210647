import { TaskStatus, TaskType } from '@shm/interface/lib/quest/model';
import { LocalTaskType } from '../componentsV2/TaskStore';
import { todaySubTaskStatus } from './todaySubTaskStatus';
export const RemoteTasks = [
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_ELEME,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_VISITING_SECKILL,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SHOPPING_GUIDE,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN_UNION_YOUXUAN,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_JING_DONG_NEWBIE,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_TAKEOUT,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_PINDUODUO_NEWBIE,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_WEIPINHUI_NEWBIE,
];
const getTaskTitle = (taskType) => {
    switch (taskType) {
        case LocalTaskType.RENEW:
            return '续费极省卡';
        case LocalTaskType.PHONE_CHARGE:
            return '话费免单补贴';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_JING_DONG_NEWBIE:
            return '京东0元购';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN_UNION_YOUXUAN:
            return '美团优选';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_ELEME:
            return '饿了么下单';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN:
            return '美团外卖下单';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_VISITING_SECKILL:
            return '逛秒杀专场';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SHOPPING_GUIDE:
            return '日常逛购物教程';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN:
            return '定时领红包';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_TAKEOUT:
            return '点外卖体验返现金';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_PINDUODUO_NEWBIE:
            return '拼多多新人';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_WEIPINHUI_NEWBIE:
            return '唯品会新人';
        default:
            return '';
    }
};
const reportTypeByTask = (taskType) => {
    switch (taskType) {
        case LocalTaskType.RENEW:
        case LocalTaskType.PHONE_CHARGE:
            return 'taskdetails2';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_JING_DONG_NEWBIE:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN_UNION_YOUXUAN:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_ELEME:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_PINDUODUO_NEWBIE:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_WEIPINHUI_NEWBIE:
            return 'taskdetails1';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_VISITING_SECKILL:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SHOPPING_GUIDE:
            return 'taskdetails3';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN:
            return 'taskdetails4';
        default:
            return '';
    }
};
const reportStatusByTask = (task) => {
    if (RemoteTasks.includes(task.taskType)) {
        // 签到任务状态埋点： 主任务状态-子任务状态-第几天
        if (task.taskType === TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SIGN_IN) {
            const { todaySubTask, index } = todaySubTaskStatus(task);
            return `${task.taskStatus}-${todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.taskStatus}-${index + 1}`;
        }
        return `${(task === null || task === void 0 ? void 0 : task.taskStatus) || TaskStatus.TASK_STATUS_UNDEFINED}`;
    }
    return `${TaskStatus.TASK_STATUS_ACCEPTED}`;
};
const getReportParams = (task) => {
    return {
        type: reportTypeByTask(task.taskType),
        task_type: `${task.taskType}`,
        name: getTaskTitle(task.taskType),
        status: reportStatusByTask(task),
    };
};
export { getReportParams, getTaskTitle };
