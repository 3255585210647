import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// 开通会员引导弹窗
import { RouteNameEnum, navigateTo } from '@shm/interface/lib/web.app';
import { useCallback, useEffect } from 'react';
import { webpImage } from '../../service/images';
import { Modal } from '../Modal';
import imageIcon from './assets/miao.png';
import styles from './styles.css';
export const BuyMemberPop = ({ title = '仅限招财符用户专享', intro = null, btnText = '领取招财符', source = '', onClose, reportView, reportClick, reportClose, }) => {
    // 领取招财符
    const onConfirm = useCallback(() => {
        if (reportClick) {
            reportClick();
        }
        navigateTo({
            name: RouteNameEnum.MEMBERSHIP,
            params: {
                header: 0,
                bounces: 0,
                from: source,
            },
        });
        if (onClose) {
            onClose();
        }
    }, [onClose, reportClick, source]);
    // 关闭
    const onClickClose = useCallback(() => {
        if (reportClose) {
            reportClose();
        }
        if (onClose) {
            onClose();
        }
    }, [onClose, reportClose]);
    useEffect(() => {
        if (reportView) {
            reportView();
        }
    }, [reportView]);
    return (_jsx(Modal, Object.assign({ position: "center" }, { children: _jsx("section", Object.assign({ className: styles.main }, { children: _jsxs("div", Object.assign({ className: styles.mainContainer }, { children: [_jsx("img", { className: styles.bgIcon, src: webpImage(imageIcon), alt: "" }), _jsx("p", Object.assign({ className: styles.title }, { children: title })), !!intro && _jsx("p", Object.assign({ className: styles.intro }, { children: intro })), _jsx("button", Object.assign({ className: styles.confirmBtn, onClick: onConfirm }, { children: btnText })), _jsx("button", Object.assign({ className: styles.closeBtn, onClick: onClickClose }, { children: "\u5173\u95ED" }))] })) })) })));
};
BuyMemberPop.displayName = 'BuyMemberPop';
