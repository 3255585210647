import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useRef } from 'react';
import { Slide } from '../../../../../../../../components/Slide';
import { useGetAds, useReportAdView } from '../../../../../../../../service/ads';
import { webpImage } from '../../../../../../../../service/images';
import { SliderControl } from '../SliderControl';
import styles from './styles.css';
const perWidth = (document.documentElement.offsetWidth / 7.5) * 5.29;
export const Banner = () => {
    const [currentSlidePage, setCurrentSlidePage] = useState(0);
    const brandRef = useRef({});
    const onSlide = useCallback((n) => {
        setCurrentSlidePage(n);
    }, []);
    const { ads: privilegeBannerAdsList, onAdClick } = useGetAds(['life_benefit_banner_v2'], true);
    useReportAdView(privilegeBannerAdsList, brandRef.current);
    if (!privilegeBannerAdsList.length) {
        return null;
    }
    return (_jsx("div", Object.assign({ className: styles.bannerWrap }, { children: _jsxs("div", Object.assign({ className: styles.banner }, { children: [_jsx(Slide, Object.assign({ onSlide: onSlide, isAnim: true, current: currentSlidePage, perWidth: perWidth }, { children: privilegeBannerAdsList.map((item, index) => {
                        var _a;
                        return (_jsx("div", Object.assign({ className: styles.bannerItem, ref: (r) => {
                                brandRef.current[item.ID] = r;
                            }, onClick: () => onAdClick(item), "aria-hidden": true }, { children: _jsx("img", { src: webpImage((_a = item.image) === null || _a === void 0 ? void 0 : _a.imageURL), alt: "", className: styles.bannerImg }) }), index));
                    }) })), _jsx(SliderControl, { count: privilegeBannerAdsList.length, selectedIndex: currentSlidePage })] })) })));
};
Banner.displayName = 'Banner';
