// 看视频广告
import { browser } from '@shm/interface/lib/web';
import { rpc } from '@shm/interface/lib/web.app';
import { getAdText } from '@shm/text/lib/ad';
import { Hud } from '../components/Hud';
// 看激励视频
export const watchVideoAd = (position, loadingCallback) => {
    return new Promise((resolve, reject) => {
        if (!browser.isSHMApp()) {
            const hudClose = Hud.Loading({
                text: '模拟观看视频3秒钟',
            });
            window.setTimeout(() => {
                hudClose();
                if (loadingCallback) {
                    loadingCallback();
                }
                resolve({
                    ad_position: position,
                    transaction_id: 'local',
                });
            }, 1000);
            return;
        }
        const onLoad = () => {
            if (loadingCallback) {
                loadingCallback();
            }
        };
        const watchOptions = {
            timeout: 12 * 1000,
            onLoad,
        };
        rpc.watchAd(position, watchOptions, onLoad)
            .then((res) => {
            if (loadingCallback) {
                loadingCallback();
            }
            const resCompatible = res || {};
            resolve({
                ad_position: position,
                transaction_id: resCompatible.transactionID || '',
            });
        })
            .catch((err) => {
            if (loadingCallback) {
                loadingCallback();
            }
            const code = Number.parseInt(err.code, 10) || 0;
            if (code === 910) {
                // 6秒关闭的广告不要有提示
            }
            else {
                Hud.Text({
                    text: getAdText(code),
                    duration: 2000,
                });
            }
            reject(err);
        });
    });
};
