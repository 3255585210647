import { ActivityType } from '@shm/interface/lib/common/activity_type';
import { ReportMembershipScreenMemberDeliveryDetailsClick, ReportMembershipScreenMemberMoudleView, } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { navigateTo, RouteNameEnum } from '@shm/interface/lib/web.app';
import 'intersection-observer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getURLParamFrom } from '../../../service/urlParams';
const from = getURLParamFrom();
export const useFunction = (props) => {
    const { item, hiddenItem } = props;
    const [isVisibled, setVisibled] = useState(false);
    const io = useRef();
    const ref = useRef();
    const onItemClick = useCallback(() => {
        var _a, _b, _c;
        ReportMembershipScreenMemberDeliveryDetailsClick({
            from,
            title: '天天0元购',
            type: '1',
            item_id: (_a = item.base) === null || _a === void 0 ? void 0 : _a.ID,
            activity_type: (_c = (_b = item.activityInfo) === null || _b === void 0 ? void 0 : _b.detail) === null || _c === void 0 ? void 0 : _c.activityType,
        });
        navigateTo({
            name: RouteNameEnum.SAVE_MONEY_ZERO_YUAN_BUY_SHOP,
            params: {
                header: 0,
                bounces: 0,
                from,
            },
        });
    }, [item]);
    useEffect(() => {
        io.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                var _a, _b, _c;
                if (entry.isIntersecting) {
                    setVisibled(true);
                    io.current.disconnect();
                    if (hiddenItem)
                        return;
                    ReportMembershipScreenMemberMoudleView({
                        type: '1',
                        from,
                        title: '天天0元购',
                        item_id: (_a = item.base) === null || _a === void 0 ? void 0 : _a.ID,
                        activity_type: ((_c = (_b = item.activityInfo) === null || _b === void 0 ? void 0 : _b.detail) === null || _c === void 0 ? void 0 : _c.activityType) ||
                            ActivityType.ACTIVITY_TYPE_UNDEFINED,
                    });
                }
            });
        });
        io.current.observe(ref.current);
        return () => {
            io.current.disconnect();
        };
    }, [item, hiddenItem]);
    return useMemo(() => {
        return { ref, isVisibled, onItemClick };
    }, [ref, isVisibled, onItemClick]);
};
