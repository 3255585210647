import { jsx as _jsx } from "react/jsx-runtime";
import { ReturnOpeningFeeType } from '@shm/interface/lib/bonus/base/model';
import { GetReturnOpeningFeeInfoV2 } from '@shm/interface/lib/bonus/service';
import { AssetType } from '@shm/interface/lib/common/asset_type';
import { ReceiveLifeRightsCash } from '@shm/interface/lib/mall/liferights/service';
import { createContext, useCallback, useEffect, useReducer, } from 'react';
import { useUserInfo } from '../../../../../../service/platform/useUserInfo';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import { useGetAsset } from '../../../../../../service/useGetAsset';
import { useIsOpenMember } from '../../../../../membership/components/MemberPay/hooks/useIsOpenMember';
export var ReducerActions;
(function (ReducerActions) {
    ReducerActions[ReducerActions["UPDATE_STORE"] = 0] = "UPDATE_STORE";
})(ReducerActions || (ReducerActions = {}));
const from = getURLParamFrom();
const localReceiveLifeRightsCashKey = 'MEMBER_MALL/INDEX/CASH_GET';
const initStore = {
    from,
    lifeRightsHeight: 0,
    hasReturnFee: false,
};
export const StoreContext = createContext([]);
const reducer = (state, action) => {
    switch (action.type) {
        case ReducerActions.UPDATE_STORE:
            return Object.assign(Object.assign({}, state), action.payload);
        default:
            return state;
    }
};
export const WithStore = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initStore);
    const userInfo = useUserInfo();
    // 获取权益金余额
    const [, setAmount] = useGetAsset(AssetType.ASSET_TYPE_LIFE_RIGHTS_CASH);
    // 如果用户未领取过权益金 则自动领取
    useEffect(() => {
        const storeKey = `${userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID}/${localReceiveLifeRightsCashKey}`;
        const localReceiveStatus = Number(localStorage.getItem(storeKey));
        if (!localReceiveStatus) {
            ReceiveLifeRightsCash({}).then((res) => {
                const { isReceived } = res || {};
                if (isReceived) {
                    setAmount();
                    localStorage.setItem(storeKey, '1');
                }
            });
        }
    }, [setAmount, userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID]);
    // 获取用户是否有全返红包(含开通/续费)
    const updateUserReturnFeeState = useCallback(() => {
        GetReturnOpeningFeeInfoV2({
            userID: userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID,
            returnOpeningFeeTypes: [
                ReturnOpeningFeeType.RETURN_OPENING_FEE_TYPE_MEMBERSHIP_RENEWAL,
            ],
        }).then((res) => {
            var _a;
            const renewalReturnFee = (_a = res === null || res === void 0 ? void 0 : res.returnOpeningFeeInfos) === null || _a === void 0 ? void 0 : _a.find((item) => item.returnOpeningFeeType ===
                ReturnOpeningFeeType.RETURN_OPENING_FEE_TYPE_MEMBERSHIP_RENEWAL);
            dispatch({
                type: ReducerActions.UPDATE_STORE,
                payload: {
                    hasReturnFee: !!renewalReturnFee,
                },
            });
        });
    }, [userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID]);
    const isOpenMember = useIsOpenMember();
    useEffect(() => {
        if (isOpenMember !== undefined) {
            updateUserReturnFeeState();
        }
    }, [updateUserReturnFeeState, isOpenMember]);
    return _jsx(StoreContext.Provider, Object.assign({ value: [state, dispatch] }, { children: children }));
};
WithStore.displayName = 'WithStore';
