// app state 切换的时候触发
import { AppStateEnum } from '@shm/interface';
import { browser } from '@shm/interface/lib/web';
import { rpc } from '@shm/interface/lib/web.app';
import { useEffect, useCallback, useRef, useState } from 'react';
const isInApp = browser.isSHMApp();
export const useAppStateChange = () => {
    const isRef = useRef(true);
    const [active, setActive] = useState(true);
    const fn = useCallback((e) => {
        if (!isRef.current) {
            return;
        }
        if (e.state === AppStateEnum.ACTIVE) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }, []);
    const listenerRef = useRef({});
    useEffect(() => {
        if (isInApp) {
            isRef.current = true;
            rpc.addEventListener('appStateChange', fn).then((res) => {
                // useEffect执行太快，来不及移除事件监听，所以在这里监听成功过后移除以前的事件句柄
                Object.keys(listenerRef.current).forEach((v) => {
                    delete listenerRef.current[v];
                    rpc.removeEventListener(v);
                });
                listenerRef.current[res] = true;
            });
        }
        return () => {
            if (isInApp) {
                isRef.current = false;
                rpc.removeEventListener('appStateChange', fn);
            }
        };
    }, [fn]);
    return [active];
};
