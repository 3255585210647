import { getAccountInfo } from '@shm/api/lib/trade';
import { useState, useEffect, useCallback } from 'react';
/**
 * 根据资产类型 获取资产数据
 * @param assetType 资产类型
 * @param init 是否初始化
 * @deprecated 接口升级，改用useAssets
 */
export const useGetAsset = (assetType, init = true) => {
    const [data, setData] = useState({});
    const getAccount = useCallback(() => {
        return getAccountInfo().then((resp) => {
            const info = resp.assets.find((item) => item.asset_type === assetType);
            setData(info || {});
        });
    }, [assetType]);
    useEffect(() => {
        if (init) {
            getAccount();
        }
    }, [getAccount, init]);
    return [data, getAccount];
};
