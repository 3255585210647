import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TaskStatus, TaskType } from '@shm/interface/lib/quest/model';
import classNames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import { useEvent } from '../../../../../../../../service/useEvent';
import { TaskPanelContext } from '../../../TaskPanel/state';
import { UpdateTasksUnclickCountEvent } from '../../hooks/useGetAllTaskUnClickNum';
import { TaskCountDown } from '../TaskCountDown';
import styles from './styles.css';
const longItemTasks = [
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_JING_DONG_NEWBIE,
    TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN_UNION_YOUXUAN,
];
const getBtnDescText = (type) => {
    switch (type) {
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_JING_DONG_NEWBIE:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN:
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_ELEME:
            return '确认收货后到账';
        case TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_MEITUAN_UNION_YOUXUAN:
            return '取货完成后到账';
        default:
            return '确认收货后到账';
    }
};
const DescText = ({ task }) => {
    if ((task === null || task === void 0 ? void 0 : task.taskStatus) !== TaskStatus.TASK_STATUS_ACCEPTED)
        return null;
    return _jsx("span", Object.assign({ className: styles.btnDescTxt }, { children: getBtnDescText(task === null || task === void 0 ? void 0 : task.taskType) }));
};
DescText.displayName = 'DescText';
export const TaskBtn = ({ task, btnText, classname, children, }) => {
    const { getTaskClikedCache } = useContext(TaskPanelContext);
    const [taskClicked, setTaskClicked] = useState(getTaskClikedCache(task.taskType) > 0);
    const updateTaskClicked = useCallback(() => {
        setTaskClicked(getTaskClikedCache(task.taskType) > 0);
    }, [getTaskClikedCache, task.taskType]);
    useEvent(UpdateTasksUnclickCountEvent, updateTaskClicked);
    return (_jsxs("div", Object.assign({ className: classNames(styles.btnCt, {
            [styles.longItem]: longItemTasks.includes(task.taskType),
        }) }, { children: [_jsx("button", Object.assign({ className: classNames(styles.btn, classname) }, { children: btnText })), _jsx(DescText, { task: task }), _jsx(TaskCountDown, { targetTime: Number(task === null || task === void 0 ? void 0 : task.expireTime) * 1000 || 0, status: (task === null || task === void 0 ? void 0 : task.taskStatus) || 0 }), !taskClicked && _jsx("span", Object.assign({ className: styles.unclickBadge }, { children: "1" })), children] })));
};
TaskBtn.displayName = 'TaskBtn';
