import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReportMemberMallScreenOrderView } from '@shm/interface/lib/reportevents/frontend/membermallscreen';
import { rpc, RouteNameEnum, navigateTo } from '@shm/interface/lib/web.app';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { StatusBar } from '../../../../../../components/StatusBar';
import { webpImage } from '../../../../../../service/images';
import { useShowBestLuckPopup } from '../../../../../membership/services/useShowBestLuckPopup';
import { FromEnum } from '../../../../services/report';
import { ReducerActions, StoreContext } from '../WithStore';
import backIcon from './assets/back.png';
import orderIcon from './assets/order.png';
import styles from './styles.css';
export const Header = () => {
    const [, dispatch] = useContext(StoreContext);
    useShowBestLuckPopup();
    const domRef = useRef(null);
    const onBack = useCallback(() => {
        rpc.navigateClose();
    }, []);
    const onGoOrder = useCallback(() => {
        navigateTo({
            name: RouteNameEnum.MEMBER_MALL_ORDER_LIST,
            params: {
                from: FromEnum.BENEFIT,
            },
        });
        ReportMemberMallScreenOrderView({});
    }, []);
    useEffect(() => {
        window.requestAnimationFrame(() => {
            if (domRef.current) {
                dispatch({
                    type: ReducerActions.UPDATE_STORE,
                    payload: {
                        lifeRightsHeight: domRef.current.getBoundingClientRect().height,
                    },
                });
            }
        });
    }, [dispatch]);
    return (_jsxs("div", Object.assign({ className: styles.main, ref: domRef }, { children: [_jsx(StatusBar, {}), _jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("img", { src: webpImage(backIcon), alt: "", className: styles.back, onClick: onBack, "aria-hidden": true }), _jsx("div", Object.assign({ className: styles.title }, { children: "\u7701\u94B1\u5361\u4E13\u4EAB\u6743\u76CA" })), _jsxs("div", Object.assign({ className: styles.order, onClick: onGoOrder, "aria-hidden": true }, { children: [_jsx("img", { src: webpImage(orderIcon), alt: "", className: styles.orderIcon }), "\u8BA2\u5355"] }))] }))] })));
};
Header.displayName = 'Header';
