import { GetSuccessOrderCountByActivityType } from '@shm/interface/lib/officialorder/service';
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { ListFreeBuyTasksV2 } from '@shm/interface/lib/quest/service';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useUserInfo } from '../../../../../../../service/platform/useUserInfo';
import { useDidFocus } from '../../../../../../../service/useDidFocus';
import { dispatch } from '../../../../../../../service/useEvent';
import { MemberPayContext } from '../../../../../../membership/components/MemberPay/interface';
import { LocalTaskType } from '../componentsV2/TaskStore';
export const UpdateTasksUnclickCountEvent = 'UpdateTasksUnclickCountEvent';
const cacheKey = 'save-moeny-zero-yuan-buy-task-click';
export const useGetAllTaskUnClickNum = (getAllCount) => {
    const [unclickCount, setCount] = useState(0);
    const [allUnclickTaskTypes, setAllUnclickTaskTypes] = useState([]);
    const user = useUserInfo();
    const { renewMemberPackage } = useContext(MemberPayContext);
    const [unPickedRewardAmount, setUnPickedRewardAmount] = useState((renewMemberPackage === null || renewMemberPackage === void 0 ? void 0 : renewMemberPackage.subsidyAmount) || 0);
    const getTaskClikedCache = useCallback((taskType) => {
        return Number(window.localStorage.getItem(`${cacheKey}-${taskType}-${user === null || user === void 0 ? void 0 : user.ID}`));
    }, [user === null || user === void 0 ? void 0 : user.ID]);
    const getTasksLen = useCallback(() => {
        Promise.all([ListFreeBuyTasksV2({}), GetSuccessOrderCountByActivityType({})]).then((ress) => {
            var _a, _b;
            const taskList = ress[0];
            const tasks = [LocalTaskType.RENEW];
            (_a = taskList === null || taskList === void 0 ? void 0 : taskList.tasks) === null || _a === void 0 ? void 0 : _a.forEach((item) => {
                if (item.rewardAmount > 0 &&
                    item.taskStatus !== TaskStatus.TASK_STATUS_INVALID) {
                    tasks.push(item.taskType);
                }
                if (item.taskStatus !== TaskStatus.TASK_STATUS_REWARD_GIVEN) {
                    setUnPickedRewardAmount((pre) => {
                        return pre + item.rewardAmount;
                    });
                }
            });
            if (!((_b = ress[1]) === null || _b === void 0 ? void 0 : _b.count)) {
                tasks.push(LocalTaskType.PHONE_CHARGE);
                setUnPickedRewardAmount((pre) => {
                    return pre + 2990;
                });
            }
            setAllUnclickTaskTypes(tasks);
        });
    }, []);
    const getAllUnclickCount = useCallback(() => {
        let amount = 0;
        allUnclickTaskTypes.forEach((taskType) => {
            amount += getTaskClikedCache(taskType) === 0 ? 1 : 0;
        });
        setCount(amount);
    }, [allUnclickTaskTypes, getTaskClikedCache]);
    const setTaskClikedCache = useCallback((taskType) => {
        window.localStorage.setItem(`${cacheKey}-${taskType}-${user === null || user === void 0 ? void 0 : user.ID}`, '1');
        dispatch(UpdateTasksUnclickCountEvent);
    }, [user === null || user === void 0 ? void 0 : user.ID]);
    useEffect(() => {
        if (!allUnclickTaskTypes.length)
            return;
        getAllUnclickCount();
    }, [allUnclickTaskTypes.length, getAllUnclickCount]);
    useEffect(() => {
        if (getAllCount && (user === null || user === void 0 ? void 0 : user.ID))
            getTasksLen();
    }, [getAllCount, getTasksLen, user === null || user === void 0 ? void 0 : user.ID]);
    useDidFocus(() => {
        dispatch(UpdateTasksUnclickCountEvent);
    }, false);
    return {
        unclickCount,
        getTaskClikedCache,
        setTaskClikedCache,
        getAllUnclickCount,
        unPickedRewardAmount,
    };
};
