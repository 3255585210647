import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIsMountedRef } from '@shm-open/utilities/lib/react';
import { GetCategoryInfo } from '@shm/interface/lib/catalog/service';
import { toCommonItem } from '@shm/interface/lib/migration/item';
import { formatMoney, getPriceInfoV2 } from '@shm/item';
import classNames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import placeholder from '../../assets/images/placeholder.png';
import { config } from '../../config';
import { resizeImage } from '../../service/images';
import { CategoryDataContext, CategoryDataStore } from '../CategoryDataStore';
import { useFunction } from './hooks/useFunction';
import styles from './styles.css';
const GoodItem = ({ data }) => {
    const { ref, onItemClick } = useFunction({
        item: data,
    });
    const { originalPrice, arrivalPrice } = getPriceInfoV2(toCommonItem(data));
    return (_jsxs("div", Object.assign({ className: styles.item, onClick: onItemClick, "aria-hidden": true, ref: ref }, { children: [_jsx("img", { src: resizeImage(data.base.imageURL) || placeholder, alt: "\u5546\u54C1\u56FE\u7247", className: styles.itemPicture }), _jsxs("div", Object.assign({ className: styles.bottom }, { children: [_jsx("p", Object.assign({ className: styles.title }, { children: data.base.title })), _jsxs("div", Object.assign({ className: styles.btn }, { children: [_jsx("span", Object.assign({ className: styles.currency }, { children: "\u00A5" })), _jsx("span", Object.assign({ className: styles.zero }, { children: formatMoney(arrivalPrice.normal) })), _jsxs("span", Object.assign({ className: styles.originPrice }, { children: ["\u00A5", formatMoney(originalPrice)] }))] }))] }))] })));
};
GoodItem.displayName = 'GoodItem';
/**
 * 0元购商品列表预览，就是一行商品的意思
 */
const GoodsList = ({ showLines }) => {
    const { items, changeCategoryID } = useContext(CategoryDataContext);
    const lines = showLines || 1;
    const isMounted = useIsMountedRef();
    const showItems = useMemo(() => {
        if (items.length < 6)
            return [];
        if (items.length % 2 !== 0) {
            items.pop();
        }
        return items.slice(0, Math.min(lines * 6, items.length));
    }, [items, lines]);
    useEffect(() => {
        GetCategoryInfo({
            categoryID: config.ITEMS_FOR_1YUAN_BUY_CATEGORY_ID,
        }).then((result) => {
            var _a;
            if (!isMounted.current)
                return;
            result.category.children = result.category.children.filter((category) => {
                return category.ID !== config.OFFICIAL_CATEGORY_ID_IN_ZERO_BUY;
            });
            let cate = result.category;
            while (((_a = cate.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                [cate] = cate.children;
            }
            changeCategoryID(cate === null || cate === void 0 ? void 0 : cate.ID);
        });
    }, [changeCategoryID, isMounted]);
    return (_jsx("div", { children: _jsx("div", Object.assign({ className: classNames(styles.container), style: { height: `${lines * 3}rem` } }, { children: showItems.map((value, index) => (_jsx(GoodItem, { data: value }, index))) })) }));
};
GoodsList.displayName = 'GoodsList';
export const ZeroYuanBuyPreview = (props) => {
    return (_jsx(CategoryDataStore, { children: _jsx(GoodsList, Object.assign({}, props)) }));
};
ZeroYuanBuyPreview.displayName = 'ZeroYuanBuyPreview';
