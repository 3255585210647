import { useEffect } from 'react';
export const useEvent = (type, handler) => {
    useEffect(() => {
        document.body.addEventListener(type, handler, false);
        return () => {
            document.body.removeEventListener(type, handler, false);
        };
    }, [handler, type]);
};
export const dispatch = (type, detail) => {
    document.body.dispatchEvent(new CustomEvent(type, { detail }));
};
