import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getServerTime } from '@shm/interface';
import { useEffect, useMemo, useState } from 'react';
import styles from './styles.css';
export const CountDown = ({ endTime, endDesc, }) => {
    const [leftTime, setLeftTime] = useState(0);
    useEffect(() => {
        setLeftTime(Math.max(0, endTime - getServerTime() * 0.001));
        const timer = setInterval(() => {
            setLeftTime((old) => {
                return Math.max(0, old - 1);
            });
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [endTime]);
    const timeInfo = useMemo(() => {
        let n = leftTime;
        const days = Math.floor(n / 86400);
        n %= 86400;
        const hours = Math.floor(n / 3600);
        n %= 3600;
        const minutes = Math.floor(n / 60);
        n %= 60;
        const seconds = Math.floor(n);
        return {
            days,
            hours: hours < 10 ? `0${hours}` : hours,
            minutes: minutes < 10 ? `0${minutes}` : minutes,
            seconds: seconds < 10 ? `0${seconds}` : seconds,
        };
    }, [leftTime]);
    if (leftTime <= 0)
        return null;
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [timeInfo.days > 0 && (_jsxs(_Fragment, { children: [_jsx("span", { children: timeInfo.days }), "\u5929"] })), _jsx("span", { children: timeInfo.hours }), ":", _jsx("span", { children: timeInfo.minutes }), ":", _jsx("span", { children: timeInfo.seconds }), _jsx("span", Object.assign({ className: styles.endDes }, { children: endDesc }))] })));
};
CountDown.displayName = 'CountDown';
