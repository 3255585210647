import { jsx as _jsx } from "react/jsx-runtime";
// lazy-image
import { useEffect, useRef, useState, useMemo } from 'react';
import defaultImageTransparentFile from '../../assets/images/1px.png';
import defaultImageFile from '../../assets/images/placeholder.png';
import { domVisible } from '../../service/browser';
import { webpImage } from '../../service/images';
export const LazyImage = ({ lazy, defaultImage = 'placeholder', className = '', onClick, }) => {
    const imgRef = useRef();
    const defaultSrc = useMemo(() => {
        if (defaultImage === 'placeholder') {
            return webpImage(defaultImageFile);
        }
        if (defaultImage === 'transparent') {
            return webpImage(defaultImageTransparentFile);
        }
        return defaultImage;
    }, [defaultImage]);
    const [src, setSrc] = useState(defaultSrc);
    useEffect(() => {
        window.requestAnimationFrame(() => {
            if (imgRef.current) {
                domVisible(imgRef.current, {
                    key: `lazy_image_${Math.random()}`,
                }).then(() => {
                    setSrc(lazy);
                });
            }
        });
    }, [lazy]);
    return (_jsx("img", { ref: (e) => {
            imgRef.current = e;
        }, className: className, src: src, alt: "", onClick: () => {
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }, "aria-hidden": true }));
};
LazyImage.displayName = 'LazyImage';
