import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TaskStatus } from '@shm/interface/lib/quest/model';
import classNames from 'classnames';
import { webpImage } from '../../../../../../../../service/images';
import { todaySubTaskStatus } from '../../hooks/todaySubTaskStatus';
import { getBtnStyle } from '../../hooks/useBtnText';
import { BaseTaskItem } from '../BaseTaskItem';
import { TaskBtn } from '../TaskBtn';
import gouImg from './assets/gou.png';
import redRedPacketImg from './assets/red/red-packet.png';
import styles from './styles.css';
const SignDayItem = ({ subTask, lastDay, }) => {
    return (_jsx("div", Object.assign({ className: classNames(styles.dayItem, {
            [styles.dayItemChecked]: subTask.taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN,
            [styles.dayItemRightLine]: !lastDay,
            [styles.lastDayItem]: lastDay,
        }) }, { children: lastDay ? (_jsx("img", { className: styles.redpacketImg, src: webpImage(redRedPacketImg), alt: "" })) : (_jsx(_Fragment, { children: subTask.taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN ? (_jsx("img", { className: styles.gouImg, src: webpImage(gouImg), alt: "" })) : (_jsx("span", { children: subTask === null || subTask === void 0 ? void 0 : subTask.ID })) })) })));
};
SignDayItem.displayName = 'SignDayItem';
const SignDayList = ({ list }) => {
    return (_jsx("div", Object.assign({ className: styles.dayListCt }, { children: list.map((subTask, i) => (_jsx(SignDayItem, { subTask: subTask, lastDay: i === ((list === null || list === void 0 ? void 0 : list.length) || 0) - 1 }, subTask === null || subTask === void 0 ? void 0 : subTask.ID))) })));
};
SignDayList.displayName = 'SignDayList';
const getBtnText = (task) => {
    const btnUIStyle = getBtnStyle();
    if ((task === null || task === void 0 ? void 0 : task.taskStatus) === TaskStatus.TASK_STATUS_REWARD_GIVEN)
        return ['已完成', btnUIStyle.givenBtn];
    const { todayIsGiven } = todaySubTaskStatus(task);
    if (todayIsGiven)
        return ['今日已领取', btnUIStyle.givenBtn];
    return ['立即领取', btnUIStyle.redBtn];
};
export const SignTaskItem = ({ task }) => {
    const btnStyle = getBtnText(task);
    return (_jsxs(BaseTaskItem, Object.assign({ task: task }, { children: [_jsx(SignDayList, { list: (task === null || task === void 0 ? void 0 : task.subTasks) || [] }), _jsx(TaskBtn, { classname: btnStyle[1], btnText: btnStyle[0], task: task })] })));
};
SignTaskItem.displayName = 'SignTaskItem';
