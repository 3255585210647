import { useCallback } from 'react';
import { useUserInfo } from '../../../../../../../service/platform/useUserInfo';
import { useLocalStorage } from '../../../../../../../service/useLocalStorage';
/**
 * 是否需要展示饿了么模块的引导
 * <p> 判断本地缓存未记录展示过引导
 */
export const useShowEleCardBoxGuide = () => {
    const userInfo = useUserInfo();
    const [alreadyShowGuide, updateAlreadyShowGuide] = useLocalStorage((userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID) ? `ele-union-membership-guide-${userInfo === null || userInfo === void 0 ? void 0 : userInfo.ID}-from-eleCardBox` : '', false);
    const setAlreadyShowGuide = useCallback(() => {
        updateAlreadyShowGuide(true);
    }, [updateAlreadyShowGuide]);
    return { alreadyShowGuide: !!alreadyShowGuide, setAlreadyShowGuide };
};
