// 数据上报
// 点击来源
export var FromEnum;
(function (FromEnum) {
    FromEnum["UNDEFINED"] = "0";
    FromEnum["INDEX"] = "brand_total_page";
    FromEnum["ENTRY"] = "member_register_page";
    FromEnum["PAY_RESULT"] = "pay-result-page";
    FromEnum["BRAND"] = "brand-detail-page";
    FromEnum["GET_MEMBER"] = "membership-life-service";
    FromEnum["MEMBERSHIP_OPENED"] = "membership_page";
    FromEnum["MEMBERSHIP"] = "membership";
    FromEnum["BENEFIT"] = "benefit_page";
    FromEnum["ZERO_YUAN_BUY"] = "zero_yuan_buy_item_detail_page";
})(FromEnum || (FromEnum = {}));
// 页面枚举
export var PageEnum;
(function (PageEnum) {
    PageEnum["UNDEFINED"] = "0";
    PageEnum["INDEX"] = "1";
    PageEnum["BRAND"] = "2";
})(PageEnum || (PageEnum = {}));
