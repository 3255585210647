import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { parseURLParams } from '@shm-open/utilities';
import { useMemo, useRef } from 'react';
import { onClickAd, useReportAdView } from '../../../../../../../../service/ads';
import { useUserInfo } from '../../../../../../../../service/platform/useUserInfo';
import { CountDown } from '../CountDown';
import styles from './styles.css';
/**
 * 限时免费权益 运营块Item
 */
export const RightItem = ({ adInfo }) => {
    var _a, _b;
    const userInfo = useUserInfo();
    const memberStartTime = ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.currentValidityStartTime) || 0;
    const endTime = useMemo(() => {
        const strValidTime = parseURLParams(adInfo.image.linkURL)['valid-time'];
        const validTime = Number(strValidTime) || 0;
        return memberStartTime + validTime * 24 * 3600;
    }, [adInfo.image.linkURL, memberStartTime]);
    const adsDomRef = useRef({});
    useReportAdView([adInfo], adsDomRef.current);
    return (_jsxs("div", Object.assign({ ref: (e) => {
            adsDomRef.current[adInfo.ID] = e;
        }, "aria-hidden": true, onClick: () => onClickAd(adInfo), className: styles.container }, { children: [_jsx("img", { alt: "", src: (_b = adInfo.image) === null || _b === void 0 ? void 0 : _b.imageURL }), _jsx(CountDown, { endTime: endTime })] })));
};
RightItem.displayName = 'RightItem';
