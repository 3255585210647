import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getServerTime } from '@shm/interface';
import { MagicItemInstanceStatus } from '@shm/interface/lib/common/magic_item_instance_status';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetAds, useReportAdView } from '../../../../../../service/ads';
import { useDidFocus } from '../../../../../../service/useDidFocus';
import { useModuleVisible } from '../../../../serviceSaveMoneyCard/useModuleVisible';
import { EleUnionCardGuide } from '../EleUnionCardGuide';
import { CountDown } from './components/CountDown';
import { useEleUnionCurrentTask } from './service/useEleUnionTaskStatus';
import { useShowEleCardBoxGuide } from './service/useShowEleCardBoxGuide';
import styles from './styles.css';
/** 饿了么联名卡运营位模块 */
export const EleUnionCardBox = () => {
    var _a;
    const { alreadyShowGuide, setAlreadyShowGuide } = useShowEleCardBoxGuide();
    const { curTask, refreshTask, getSubTaskOrderStatus } = useEleUnionCurrentTask();
    const [orderStatus, setOrderStatus] = useState(MagicItemInstanceStatus.MAGIC_ITEM_INSTANCE_STATUS_UNDEFINED);
    const { ref } = useModuleVisible('饿了么联名卡运营位模块');
    const isCurrentTaskGainReward = orderStatus === MagicItemInstanceStatus.MAGIC_ITEM_INSTANCE_STATUS_USED;
    const [endInfo, setEndInfo] = useState({
        endDesc: '',
        endTime: 0,
    });
    const { firstAd: adInfo, onAdClick } = useGetAds(['member_new_elm_packet'], true);
    const adsDomRef = useRef({});
    useReportAdView([adInfo], adsDomRef.current);
    const refreshTaskOrderStatus = useCallback(() => {
        refreshTask().then((res) => {
            if (!res) {
                setOrderStatus(MagicItemInstanceStatus.MAGIC_ITEM_INSTANCE_STATUS_UNDEFINED);
                return;
            }
            getSubTaskOrderStatus(res).then((s) => {
                setOrderStatus(s);
            });
        });
    }, [getSubTaskOrderStatus, refreshTask]);
    const hideGuide = useCallback(() => {
        setAlreadyShowGuide();
    }, [setAlreadyShowGuide]);
    const getLeftDays = useCallback(() => {
        const leftTime = Math.max(0, endInfo.endTime - getServerTime() * 0.001);
        return Math.max(1, Math.floor(leftTime / 86400));
    }, [endInfo.endTime]);
    useEffect(() => {
        setEndInfo({
            endTime: (curTask === null || curTask === void 0 ? void 0 : curTask.endTime) || 0,
            endDesc: isCurrentTaskGainReward ? '后开启' : '后过期',
        });
    }, [curTask === null || curTask === void 0 ? void 0 : curTask.endTime, isCurrentTaskGainReward]);
    useEffect(() => {
        refreshTaskOrderStatus();
    }, [refreshTaskOrderStatus]);
    useDidFocus(refreshTaskOrderStatus, false);
    if (!adInfo || !curTask)
        return _jsx(_Fragment, {});
    return (_jsxs("div", Object.assign({ ref: ref, className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.titleContainer }, { children: [_jsxs("p", Object.assign({ className: styles.title }, { children: [_jsx("span", { children: "\u514D\u8D39\u9886" }), "\u997F\u4E86\u4E48\u5403\u8D27\u5361"] })), _jsx("p", { children: "\u6BCF\u6708\u53EF\u9886\u4E00\u5F20\u6708\u5361" })] })), _jsxs("div", Object.assign({ ref: (e) => {
                    adsDomRef.current[adInfo.ID] = e;
                }, className: styles.content, onClick: () => onAdClick(adInfo), "aria-hidden": true }, { children: [_jsx("img", { className: styles.adImage, src: (_a = adInfo.image) === null || _a === void 0 ? void 0 : _a.imageURL, alt: "" }), !isCurrentTaskGainReward && (_jsx(CountDown, { endTime: endInfo.endTime, endDesc: endInfo.endDesc })), isCurrentTaskGainReward && (_jsxs("p", Object.assign({ className: styles.timeDesc }, { children: [getLeftDays(), "\u5929\u540E\u53EF\u7EE7\u7EED\u514D\u8D39\u9886"] })))] })), !alreadyShowGuide && _jsx(EleUnionCardGuide, { hide: hideGuide })] })));
};
EleUnionCardBox.displayName = 'EleUnionCardBox';
