import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef } from 'react';
import { useGetAds, useReportAdView } from '../../../../../../service/ads';
import { useAppStateChange } from '../../../../../../service/useAppStateChange';
import { useDidFocus } from '../../../../../../service/useDidFocus';
import { useIsOpenMember } from '../../../../../membership/components/MemberPay/hooks/useIsOpenMember';
import styles from './styles.css';
const AdRowItem = ({ ads, onAdClick }) => {
    const adsDomRef = useRef({});
    useReportAdView(ads, adsDomRef.current);
    if (!ads.length)
        return null;
    return (_jsx("div", Object.assign({ className: styles.adRowItem }, { children: ads.map((item) => {
            var _a;
            return (_jsx("img", { "aria-hidden": true, ref: (e) => {
                    adsDomRef.current[item.ID] = e;
                }, src: (_a = item.image) === null || _a === void 0 ? void 0 : _a.imageURL, onClick: () => {
                    onAdClick(item);
                }, className: styles.adItem, style: { width: `${7.1 / ads.length}rem` }, alt: "" }, item.ID));
        }) })));
};
AdRowItem.displayName = 'AdRowItem';
const PositionList = ['member_yunyingwei_one', 'member_yunyingwei_two', 'member_yunyingwei_three'];
export const RenewOperationBanner = () => {
    const { ads, onAdClick, onGetAds } = useGetAds(PositionList, false);
    const [isActive] = useAppStateChange();
    const adRowList = useMemo(() => {
        return [
            ads.filter((ad) => ad.position === PositionList[0]),
            ads.filter((ad) => ad.position === PositionList[1]),
            ads.filter((ad) => ad.position === PositionList[2]),
        ];
    }, [ads]);
    const isOpenMember = useIsOpenMember();
    useEffect(() => {
        if (isActive || isOpenMember) {
            onGetAds();
        }
    }, [isActive, isOpenMember, onGetAds]);
    useDidFocus(onGetAds);
    return (_jsx("div", Object.assign({ className: styles.renewOpretaBannerCt }, { children: adRowList.map((adList, i) => (_jsx(AdRowItem, { ads: adList, onAdClick: onAdClick }, i))) })));
};
RenewOperationBanner.displayName = 'RenewOperationBanner';
