import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ReportPopupPopupClick, ReportPopupPopupClose, ReportPopupPopupView, } from '@shm/interface/lib/reportevents/frontend/popup';
import { useCallback, useEffect, useState } from 'react';
import { useUserInfo } from '../../../../../../service/platform/useUserInfo';
import { TaskPanel, } from '../../../../../save-money-zero-yuan-buy/pages/shop/components/TaskPanel';
import guideIcon from './assets/guide-icon.png';
import styles from './styles.module.less';
export const RestrictModal = ({ onClose }) => {
    var _a, _b;
    const maxRenewalTimesAllowed = ((_b = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo) === null || _b === void 0 ? void 0 : _b.maxRenewalTimesAllowed) || 0;
    const [showTaskPanel, setShowTaskPanel] = useState(false);
    const [showLimitModal, setShowLimitModal] = useState(true);
    const onOpenTask = useCallback(() => {
        setShowTaskPanel(true);
        setShowLimitModal(false);
        ReportPopupPopupClick({ type: '93' });
    }, []);
    const onCloseHandle = useCallback(() => {
        onClose();
        ReportPopupPopupClose({ type: '93' });
    }, [onClose]);
    useEffect(() => {
        ReportPopupPopupView({ type: '93' });
    }, []);
    return (_jsxs(_Fragment, { children: [showLimitModal && (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", { className: styles.mark, "aria-hidden": true, onClick: onCloseHandle }), _jsxs("div", Object.assign({ className: styles.modalBody }, { children: [_jsx("span", { className: styles.titleIcon }), _jsx("span", Object.assign({ className: styles.title }, { children: "\u60A8\u7684\u7EED\u8D39\u6B21\u6570\u5DF2\u8FBE\u4E0A\u9650" })), _jsxs("span", Object.assign({ className: styles.subtitle }, { children: ["\u7EED\u8D39\u6B21\u6570\u6700\u591A", _jsx("span", { children: maxRenewalTimesAllowed }), "\u6B21"] })), _jsx("img", { src: guideIcon, className: styles.guideIcon, alt: "" }), _jsx("button", Object.assign({ onClick: onOpenTask, className: styles.button }, { children: "\u505A\u4EFB\u52A1\u98860\u5143\u8D2D\u8865\u8D34\u91D1" }))] })), _jsx("button", { onClick: onCloseHandle, className: styles.buttonClose })] }))), _jsx(TaskPanel, { taskUIStyle: 3 /* TASK_UI_STYLE_ENUM_MEMBER */, showTaskPop: showTaskPanel, setShowTaskPop: setShowTaskPanel, onClose: onClose })] }));
};
RestrictModal.displayName = 'RestrictModal';
