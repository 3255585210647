import { ReportMembershipScreenMemberMoudleView } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { useEffect, useRef, useState } from 'react';
import { useUserInfo } from '../../../service/platform/useUserInfo';
import { getURLParamFrom } from '../../../service/urlParams';
const from = getURLParamFrom();
/** 模块曝光 */
export const useModuleVisible = (title, onViewCallback) => {
    var _a, _b;
    const membershipInfo = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo;
    const [moduleShow, setModuleShow] = useState(false);
    const io = useRef();
    const ref = useRef();
    useEffect(() => {
        if (!ref.current)
            return undefined;
        io.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                var _a;
                if (entry.isIntersecting) {
                    // 5089_0_1470
                    ReportMembershipScreenMemberMoudleView({
                        from,
                        type: '1',
                        state: `${(_a = membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.familyInfo) === null || _a === void 0 ? void 0 : _a.status}`,
                        title,
                    });
                    setModuleShow(true);
                    if (onViewCallback) {
                        onViewCallback();
                    }
                    io.current.disconnect();
                }
            });
        }, {
            threshold: [0.5],
        });
        io.current.observe(ref.current);
        return () => {
            io.current.disconnect();
        };
    }, [(_b = membershipInfo === null || membershipInfo === void 0 ? void 0 : membershipInfo.familyInfo) === null || _b === void 0 ? void 0 : _b.status, onViewCallback, title]);
    return { ref, moduleShow };
};
