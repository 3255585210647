import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { useEffect } from 'react';
import { countdownFormat, useCountdown } from '../../../../../../../../service/useCountdown';
import styles from './styles.css';
export const TaskCountDown = ({ targetTime, status, }) => {
    const [timeLeft, stopIntervalTimer] = useCountdown(targetTime, {
        intervalTime: 100,
    });
    const { day, hour, minute, second, millisecond } = countdownFormat(timeLeft);
    useEffect(() => {
        if (timeLeft <= 0)
            stopIntervalTimer();
    }, [stopIntervalTimer, timeLeft]);
    useEffect(() => {
        return () => {
            stopIntervalTimer();
        };
    }, [stopIntervalTimer]);
    if (timeLeft <= 0 || status !== TaskStatus.TASK_STATUS_AVAILABLE)
        return null;
    return (_jsxs("div", Object.assign({ className: styles.countDownCt }, { children: [_jsx("span", { children: Number(day) * 24 + Number(hour) }), _jsx("span", { children: ":" }), _jsx("span", { children: minute }), _jsx("span", { children: ":" }), _jsx("span", { children: second }), _jsx("span", { children: "." }), _jsx("span", { children: millisecond.substr(0, 1) })] })));
};
TaskCountDown.displayName = 'TaskCountDown';
