import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatMoney } from '@shm/interface';
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { ReportOneYuanBuyScreenButtonClick, ReportOneYuanBuyScreenButtonView, ReportOneYuanBuyScreenPopupClick, ReportOneYuanBuyScreenPopupClose, ReportOneYuanBuyScreenPopupView, } from '@shm/interface/lib/reportevents/frontend/oneyuanbuyscreen';
import { useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { Hud } from '../../../../../../components/Hud';
import { Outside } from '../../../../../../components/Outside';
import { webpImage } from '../../../../../../service/images';
import { useDidFocus } from '../../../../../../service/useDidFocus';
import { watchVideoAd } from '../../../../../../service/watchVideo';
import { todaySubTaskStatus } from '../Task/hooks/todaySubTaskStatus';
import { useTask } from '../Task/hooks/useTask';
import { TaskPanelContext } from '../TaskPanel/state';
import closeImg from './assets/close.png';
import videoIconImg from './assets/video-icon.png';
import styles from './styles.css';
const WatchVideoBtns = ({ subTask, onClose, onWatchVideoClick }) => {
    const { completeWatchVideoAdTask } = useTask();
    const onWatchVideo = useCallback(() => {
        ReportOneYuanBuyScreenButtonClick({ name: 'get' });
        onWatchVideoClick();
        const hide = Hud.Loading();
        watchVideoAd('0yuan_task_jili_video')
            .then(() => {
            if (subTask)
                completeWatchVideoAdTask(subTask);
        })
            .finally(hide);
    }, [completeWatchVideoAdTask, onWatchVideoClick, subTask]);
    const cancleClick = useCallback(() => {
        ReportOneYuanBuyScreenButtonClick({ name: 'giveup' });
        onClose();
    }, [onClose]);
    useEffect(() => {
        ReportOneYuanBuyScreenButtonView({ name: 'get' });
    }, []);
    return (_jsxs("div", Object.assign({ className: styles.watchVideoBtnCt }, { children: [_jsxs("div", Object.assign({ onClick: onWatchVideo, "aria-hidden": true, className: styles.watchVideoBtn }, { children: [_jsx("span", { children: "\u770B\u89C6\u9891\u9886\u989D\u5916\u7EA2\u5305" }), _jsx("img", { src: videoIconImg, alt: "" })] })), _jsx("span", Object.assign({ onClick: cancleClick, "aria-hidden": true }, { children: "\u6682\u4E0D\u9700\u8981" }))] })));
};
WatchVideoBtns.displayName = 'WatchVideoBtns';
export const TaskSignRewardPop = () => {
    var _a;
    const { setShowTaskPop, taskRewardPopInfo, setTaskRewardPopInfo } = useContext(TaskPanelContext);
    const { todaySubTask } = todaySubTaskStatus(taskRewardPopInfo);
    const hasVideoReward = useMemo(() => {
        var _a;
        return ((_a = todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.nextOne) === null || _a === void 0 ? void 0 : _a.taskStatus) === TaskStatus.TASK_STATUS_UNDEFINED;
    }, [(_a = todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.nextOne) === null || _a === void 0 ? void 0 : _a.taskStatus]);
    const [show, setShow] = useState(true);
    const reportParams = useMemo(() => {
        return {
            type: 'signtaskdone',
            task_type: `${taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType}`,
            name: '定时领红包',
            amount: `${todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.rewardAmount}`,
        };
    }, [taskRewardPopInfo === null || taskRewardPopInfo === void 0 ? void 0 : taskRewardPopInfo.taskType, todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.rewardAmount]);
    const onWatchVideoClick = useCallback(() => {
        setShow(false);
    }, []);
    const onClose = useCallback(() => {
        ReportOneYuanBuyScreenPopupClose(reportParams);
        setTaskRewardPopInfo({});
        setShowTaskPop(true);
    }, [reportParams, setShowTaskPop, setTaskRewardPopInfo]);
    const onContinueClick = useCallback(() => {
        ReportOneYuanBuyScreenPopupClick(reportParams);
        setTaskRewardPopInfo({});
        setShowTaskPop(true);
    }, [reportParams, setShowTaskPop, setTaskRewardPopInfo]);
    const init = useRef(true);
    const reportView = useCallback(() => {
        if (!init.current)
            return;
        init.current = false;
        ReportOneYuanBuyScreenPopupView(reportParams);
    }, [reportParams]);
    useDidFocus(reportView);
    if (!show)
        return null;
    return (_jsx("div", Object.assign({ className: styles.mask }, { children: _jsx(Outside, Object.assign({ clickCallback: onClose }, { children: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx("span", Object.assign({ className: styles.titleTxt }, { children: "\u606D\u559C\u83B7\u5F97\u8865\u8D34\u91D1\u7EA2\u5305" })), _jsxs("span", Object.assign({ className: styles.priceTxt }, { children: [formatMoney((todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.rewardAmount) || 0), _jsx("span", { children: "\u5143" })] })), (todaySubTask === null || todaySubTask === void 0 ? void 0 : todaySubTask.ID) !== '7' && (_jsx("span", Object.assign({ className: styles.descTxt }, { children: "\u660E\u65E5\u9886\u66F4\u591A\u54E6" }))), hasVideoReward ? (_jsx(WatchVideoBtns, { subTask: todaySubTask, onClose: onClose, onWatchVideoClick: onWatchVideoClick })) : (_jsx("button", Object.assign({ className: styles.okBtn, onClick: onContinueClick }, { children: "\u7EE7\u7EED\u8D5A\u8865\u8D34\u91D1" })))] })), _jsx("img", { "aria-hidden": true, onClick: onClose, className: styles.closeBtn, src: webpImage(closeImg), alt: "" })] }) })) })));
};
TaskSignRewardPop.displayName = 'TaskSignRewardPop';
