import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TaskStatus, TaskType } from '@shm/interface/lib/quest/model';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { moneyFormat } from '../../../../../../../../utils/conversion';
import { MemberPayContext } from '../../../../../../../membership/components/MemberPay/interface';
import { LocalTaskType } from '../TaskStore';
import styles from './styles.css';
export const TaskRewardText = ({ task }) => {
    const { renewMemberPackage } = useContext(MemberPayContext);
    const isTaskGained = task.taskStatus === TaskStatus.TASK_STATUS_REWARD_GIVEN;
    const rewardAmount = useMemo(() => {
        if (task.taskType === TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SHOPPING_GUIDE) {
            return isTaskGained ? '上不封顶' : moneyFormat(task.rewardAmount);
        }
        if (task.taskType === LocalTaskType.RENEW) {
            return moneyFormat((renewMemberPackage === null || renewMemberPackage === void 0 ? void 0 : renewMemberPackage.subsidyAmount) || 0);
        }
        return moneyFormat(task.rewardAmount);
    }, [isTaskGained, renewMemberPackage, task.rewardAmount, task.taskType]);
    return (_jsx(_Fragment, { children: task.taskType === TaskType.TASK_TYPE_FREE_BUY_ALLOWANCE_SHOPPING_GUIDE ? (_jsxs("span", Object.assign({ className: classNames(styles.rewardTxt, {
                [styles.rewardSmallTxt]: isTaskGained,
            }) }, { children: [rewardAmount, !isTaskGained && _jsx("span", { children: "\u5143" })] }))) : (_jsxs("span", Object.assign({ className: classNames(styles.rewardTxt, {
                [styles.rewardLongTxt]: `${rewardAmount}`.includes('.'),
            }) }, { children: [rewardAmount, _jsx("span", { children: "\u5143" })] }))) }));
};
TaskRewardText.displayName = 'TaskRewardText';
