import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { GetSaveMoneyCardTaskV2 } from '@shm/interface/lib/quest/service';
import { lazy, Suspense, useCallback, useEffect, useState, } from 'react';
import { LoadingPage } from '../../../../../../components/LoadingPage';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import { useDidFocus } from '../../../../../../service/useDidFocus';
import { PageContext } from './services/pageContext';
const OrderBonus = lazy(() => import('./components/OrderBonus').then((module) => {
    return { default: module.OrderBonus };
}));
const FullReturn = lazy(() => import('./components/FullReturn').then((module) => {
    return { default: module.FullReturn };
}));
const queryFrom = getURLParamFrom();
// 失效的过期时间
const invalidExpireTime = 2 * 86400;
// 完成的过期时间
const finishedExpireTime = 7 * 86400;
// 奖励的渲染状态
export var FreeShopStatusEnum;
(function (FreeShopStatusEnum) {
    FreeShopStatusEnum[FreeShopStatusEnum["UNDEFINED"] = 0] = "UNDEFINED";
    // 商品列表
    FreeShopStatusEnum[FreeShopStatusEnum["SHOP_LIST"] = 1] = "SHOP_LIST";
    // 奖励状态
    FreeShopStatusEnum[FreeShopStatusEnum["BONUS"] = 2] = "BONUS";
})(FreeShopStatusEnum || (FreeShopStatusEnum = {}));
// 根据接口返回的数据决定奖励渲染状态
const formatTaskStatus = (status, finishTime) => {
    const currentTimeStamp = Math.floor(new Date().getTime() / 1000);
    // 已完成
    if (status === TaskStatus.TASK_STATUS_REWARD_GIVEN) {
        // 如果大于n天了
        if (currentTimeStamp - finishTime > finishedExpireTime) {
            return FreeShopStatusEnum.SHOP_LIST;
        }
        return FreeShopStatusEnum.BONUS;
    }
    // 已过期
    if (status === TaskStatus.TASK_STATUS_INVALID) {
        // 如果大于n天了
        if (currentTimeStamp - finishTime > invalidExpireTime) {
            return FreeShopStatusEnum.SHOP_LIST;
        }
        return FreeShopStatusEnum.BONUS;
    }
    // 如果是创建了或进程中
    if (status) {
        return FreeShopStatusEnum.BONUS;
    }
    return FreeShopStatusEnum.UNDEFINED;
};
// 获取奖励的渲染类型
export const useFreeShopStatus = () => {
    // 返回奖励的状态
    const getFreeShopStatus = useCallback(() => {
        return new Promise((resolve) => {
            GetSaveMoneyCardTaskV2({})
                .then((res) => {
                const { task } = res;
                const { status, ID, finishTime } = task || {};
                if (!ID) {
                    resolve(FreeShopStatusEnum.SHOP_LIST);
                    return;
                }
                resolve(formatTaskStatus(status, finishTime));
            })
                .catch(() => {
                resolve(FreeShopStatusEnum.UNDEFINED);
            });
        });
    }, []);
    return getFreeShopStatus;
};
// 0元全返
export const FreeShop = ({ className, freeShopStatus: freeShopStatusProp = FreeShopStatusEnum.UNDEFINED, onHideContainer, }) => {
    const [freeShopStatus, setFreeShopStatus] = useState(freeShopStatusProp);
    const getFreeShopStatus = useFreeShopStatus();
    const refreshFreeShopStatus = useCallback(() => {
        getFreeShopStatus().then((res) => {
            setFreeShopStatus(res);
        });
    }, [getFreeShopStatus]);
    useDidFocus(refreshFreeShopStatus, false);
    useEffect(() => {
        if (freeShopStatus === undefined) {
            refreshFreeShopStatus();
        }
    }, [freeShopStatus, refreshFreeShopStatus]);
    if (freeShopStatus === FreeShopStatusEnum.UNDEFINED) {
        return null;
    }
    return (_jsx(PageContext.Provider, Object.assign({ value: {
            className,
            from: queryFrom,
        } }, { children: _jsxs(Suspense, Object.assign({ fallback: _jsx(LoadingPage, {}) }, { children: [freeShopStatus === FreeShopStatusEnum.BONUS && _jsx(OrderBonus, {}), freeShopStatus === FreeShopStatusEnum.SHOP_LIST && (_jsx(FullReturn, { onHideContainer: onHideContainer }))] })) })));
};
FreeShop.displayName = 'FreeShop';
