import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBtnText } from '../../hooks/useBtnText';
import { BaseTaskItem } from '../BaseTaskItem';
import { TaskBtn } from '../TaskBtn';
import { JDShopList } from './components/JDShopList';
export const JDNewerTaskItem = ({ task }) => {
    const btnInfo = useBtnText(task.taskType, (task === null || task === void 0 ? void 0 : task.taskStatus) || 0);
    return (_jsxs(BaseTaskItem, Object.assign({ task: task }, { children: [_jsx(JDShopList, {}), _jsx(TaskBtn, { classname: btnInfo.btnStyle, btnText: btnInfo.btnText, task: task })] })));
};
JDNewerTaskItem.displayName = 'JDNewerTaskItem';
