import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { parseURLParams } from '@shm-open/utilities';
import { getServerTime } from '@shm/interface';
import { useEffect, useState } from 'react';
import { onListAds } from '../../../../../../service/ads';
import { useUserInfo } from '../../../../../../service/platform/useUserInfo';
import { RightItem } from './components/RightItem';
import styles from './styles.css';
/**
 * 限时免费权益 运营块
 */
const formatTitle = (title) => {
    const res = title.replace(/#.*#/g, (str) => {
        return `<span style="color: #FA392B;">${str.replace(/#/g, '')}</span>`;
    });
    return (_jsx("p", { dangerouslySetInnerHTML: {
            __html: res,
        } }));
};
const RightContent = ({ adList }) => {
    const [title, setTitle] = useState();
    const [subTitle, setSubTitle] = useState('');
    useEffect(() => {
        const rawTitle = adList[0].title.split('|');
        if (rawTitle.length) {
            setTitle(formatTitle(rawTitle[0]));
            setSubTitle(rawTitle.length > 1 ? rawTitle[1] : '');
        }
    }, [adList]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.titleContainer }, { children: [_jsxs("div", Object.assign({ className: styles.title }, { children: [title, _jsx("span", { className: styles.titleStrong })] })), subTitle && _jsx("p", { children: `${subTitle}` })] })), adList.map((item) => {
                return _jsx(RightItem, { adInfo: item }, item.ID);
            })] })));
};
RightContent.displayName = 'RightContent';
export const FreeRightPositionBox = () => {
    var _a;
    const [adList, setAdList] = useState([]);
    const userInfo = useUserInfo();
    useEffect(() => {
        onListAds(['shengqian_member_free_right']).then((res) => {
            var _a;
            const trueAdList = [];
            const now = getServerTime();
            const memberStartTime = ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.currentValidityStartTime) || 0;
            res.forEach((item) => {
                const strValidTime = parseURLParams(item.image.linkURL)['valid-time'];
                const validTime = Number(strValidTime) || 0;
                if (validTime <= 0)
                    return;
                if (memberStartTime + validTime * 24 * 3600 < now * 0.001)
                    return;
                trueAdList.push(item);
            });
            setAdList(trueAdList);
        });
    }, [(_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.currentValidityStartTime]);
    if (!(adList === null || adList === void 0 ? void 0 : adList.length))
        return null;
    return _jsx(RightContent, { adList: adList });
};
FreeRightPositionBox.displayName = 'FreeRightPositionBox';
