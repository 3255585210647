import { ListenerHolder } from '@shm-open/utilities';
import { useRefWrapper, useUpdateEffect } from '@shm-open/utilities/lib/react';
import { logger } from '@shm/interface';
import { useState, useCallback, useEffect } from 'react';
const logCategory = 'platform:storage';
const stringifiedUndefined = 'undefined';
const listenerHolder = new ListenerHolder();
export function useLocalStorage(key, initialValue) {
    const initialValueRef = useRefWrapper(initialValue);
    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = useCallback(() => {
        // Prevent build error "window is undefined" but keep keep working
        if (typeof window === 'undefined' || !key) {
            return initialValueRef.current;
        }
        try {
            const item = window.localStorage.getItem(key);
            if (!item) {
                return initialValueRef.current;
            }
            if (item === stringifiedUndefined) {
                return undefined;
            }
            return JSON.parse(item);
        }
        catch (error) {
            logger.warn(`Error reading localStorage key “${key}”:${error}`, logCategory);
            return initialValueRef.current;
        }
    }, [key, initialValueRef]);
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(readValue);
    useUpdateEffect(() => {
        setStoredValue(readValue());
    }, [key, readValue]);
    useEffect(() => {
        const subscription = listenerHolder.addListener(key, (value) => {
            setStoredValue(value);
        });
        return subscription;
    }, [key]);
    const storedValueRef = useRefWrapper(storedValue);
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback((value) => {
        if (!key) {
            logger.warn(`try to set localStorage without a valid key, the setting is skipped`, logCategory);
            return;
        }
        // Prevent build error "window is undefined" but keep working
        if (typeof window === 'undefined') {
            logger.warn(`try to set localStorage key “${key}” even though environment is not a client`, logCategory);
        }
        try {
            // Allow value to be a function so we have the same API as useState
            const newValue = value instanceof Function ? value(storedValueRef.current) : value;
            // Save to local storage
            window.localStorage.setItem(key, newValue !== undefined ? JSON.stringify(newValue) : stringifiedUndefined);
            // notify the change - all instances of this key will be notified including the one that just set it
            listenerHolder.dispatch(key, newValue);
        }
        catch (error) {
            logger.warn(`Error setting localStorage key “${key}”:${error}`, logCategory);
        }
    }, [key, storedValueRef]);
    return [storedValue, setValue];
}
