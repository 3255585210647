import { useContext, useEffect } from 'react';
import { PopUpContext } from '../../../components/PopUpStore';
import { onListAds, onReportAdView } from '../../../service/ads';
import { BestLuckPopup } from '../components/BestLuckPopup';
export const useShowBestLuckPopup = () => {
    const { showPopUp } = useContext(PopUpContext);
    useEffect(() => {
        onListAds(['mbship_fir_rem']).then((res) => {
            if (res === null || res === void 0 ? void 0 : res[0]) {
                showPopUp(BestLuckPopup);
                onReportAdView(res[0]);
            }
        });
    }, [showPopUp]);
};
