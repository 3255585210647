import { createContext } from 'react';
export const CategroyNames = {};
export const setCategroyName = (cateId, name) => {
    CategroyNames[cateId] = name;
};
export const ZeroYuanBuyShopContext = createContext({});
export var PopupTypeEnum;
(function (PopupTypeEnum) {
    /** 未定义 */
    PopupTypeEnum[PopupTypeEnum["POPUP_TYPE_UNDEFINED"] = 0] = "POPUP_TYPE_UNDEFINED";
    /** 红包弹窗 */
    PopupTypeEnum[PopupTypeEnum["POPUP_TYPE_PACKAGERED"] = 1] = "POPUP_TYPE_PACKAGERED";
    /** 任务弹窗 */
    PopupTypeEnum[PopupTypeEnum["POPUP_TYPE_TASK"] = 2] = "POPUP_TYPE_TASK";
    /** 商品引导 */
    PopupTypeEnum[PopupTypeEnum["POPUP_TYPE_ITEM_GUIDE"] = 3] = "POPUP_TYPE_ITEM_GUIDE";
})(PopupTypeEnum || (PopupTypeEnum = {}));
export var PopupPackageRedStyle;
(function (PopupPackageRedStyle) {
    /** 默认样式 */
    PopupPackageRedStyle[PopupPackageRedStyle["NORMAL"] = 1] = "NORMAL";
    /** 灰度实验样式 */
    PopupPackageRedStyle[PopupPackageRedStyle["GRAY"] = 2] = "GRAY";
})(PopupPackageRedStyle || (PopupPackageRedStyle = {}));
