import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Status } from '@shm/interface/lib/membership/model';
import { FirstOpenMembershipIsSaveMoneyCard } from '@shm/interface/lib/membership/service';
import { ReportMembershipScreenH5ScreenView } from '@shm/interface/lib/reportevents/frontend/membershipscreen';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PopUpStore } from '../../../../../../components/PopUpStore';
import { AllReturnOrderContext } from '../../../../../../service/business/AllReturnOrderStore';
import { useUserInfo } from '../../../../../../service/platform/useUserInfo';
import { getURLParamFrom } from '../../../../../../service/urlParams';
import { useDidFocus } from '../../../../../../service/useDidFocus';
import { useLocalStorage } from '../../../../../../service/useLocalStorage';
import { useMemberPay } from '../../../../../membership/components/MemberPay/hooks/useMemberPay';
import { BrandBox } from '../BrandBox';
import { Card } from '../Card';
import { EleUnionCardBox } from '../EleUnionCardBox';
import { ExtraCashBackBox } from '../ExtraCashBackBox';
import { ExtraCashbackStore } from '../ExtraCashbackStore';
import { FreeRightPositionBox } from '../FreeRightPositionBox';
import { FreeShopBox } from '../FreeShopBox';
import { FreeShop, FreeShopStatusEnum, useFreeShopStatus } from '../FreeShopV2';
import { Header } from '../Header';
import { LifeRights } from '../LifeRights';
import { OpenMemberAllReturnNewbieApp } from '../OpenMemberAllReturnNewbiePopup';
import { RenewBottomBanner } from '../RenewBottomBanner';
import { RenewOperationBanner } from '../RenewOperationBanner';
import { ReturnRefundWithoutConditionPopup } from '../ReturnRefundWithoutConditionPopup';
import { WithStore } from '../WithStore';
import { ZeroYuanBuyBox } from '../ZeroYuanBuyBox';
import styles from './styles.css';
// lazy
// eslint-disable-next-line max-lines-per-function
export const Main = () => {
    var _a, _b;
    const userInfo = useUserInfo();
    const [firstOpenIsSaveMoneyCard, setFirstOpenIsSaveMoneyCard] = useState(false);
    const [freeShopStatus, setFreeShopStatus] = useState(FreeShopStatusEnum.UNDEFINED);
    const getFreeShopStatus = useFreeShopStatus();
    const pay = useMemberPay();
    const { orderInfo } = useContext(AllReturnOrderContext);
    // 限制自动续费只执行一次
    const [renewWithOrder, setRenewWithOrder] = useLocalStorage('renewWithOrder', '');
    const refreshFreeShopStatus = useCallback(() => {
        getFreeShopStatus().then((res) => {
            setFreeShopStatus(res);
        });
    }, [getFreeShopStatus]);
    useDidFocus(refreshFreeShopStatus, true);
    useEffect(() => {
        // 5089_0_1062
        ReportMembershipScreenH5ScreenView({
            from: getURLParamFrom(),
            type: '1', // 1表示是省钱卡会员版本
        });
    }, []);
    useEffect(() => {
        FirstOpenMembershipIsSaveMoneyCard({}).then((res) => {
            setFirstOpenIsSaveMoneyCard(res.isSaveMoneyCard);
        });
    }, []);
    useEffect(() => {
        var _a, _b;
        if (!orderInfo ||
            renewWithOrder === ((_a = orderInfo === null || orderInfo === void 0 ? void 0 : orderInfo.orderDetail) === null || _a === void 0 ? void 0 : _a.ID) ||
            ((_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _b === void 0 ? void 0 : _b.status) !== Status.STATUS_EXPIRED)
            return;
        pay({
            reportParams: {
                type: '23',
                position: 'member_mall_auto_renew_with_allreturn_order',
            },
        }, true).then(() => {
            var _a;
            setRenewWithOrder((_a = orderInfo === null || orderInfo === void 0 ? void 0 : orderInfo.orderDetail) === null || _a === void 0 ? void 0 : _a.ID);
        });
    }, [orderInfo, pay, renewWithOrder, setRenewWithOrder, (_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _a === void 0 ? void 0 : _a.status]);
    return (_jsx("div", Object.assign({ className: styles.wrap }, { children: _jsx(WithStore, { children: _jsx(PopUpStore, { children: _jsxs(ExtraCashbackStore, { children: [_jsx(Header, {}), _jsx(Card, {}), _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(BrandBox, {}), _jsx(RenewOperationBanner, {}), ((_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.membershipInfo) === null || _b === void 0 ? void 0 : _b.isSaveMoneyCardElemeMembershipType) && (_jsx(EleUnionCardBox, {})), _jsx(EleUnionCardBox, {}), freeShopStatus === FreeShopStatusEnum.BONUS && (_jsx(FreeShop, { className: styles.freeShop, freeShopStatus: FreeShopStatusEnum.BONUS })), _jsx(ZeroYuanBuyBox, {}), firstOpenIsSaveMoneyCard &&
                                    freeShopStatus === FreeShopStatusEnum.SHOP_LIST && _jsx(FreeShopBox, {}), firstOpenIsSaveMoneyCard && _jsx(ExtraCashBackBox, {}), _jsx(FreeRightPositionBox, {}), _jsx(LifeRights, {}), _jsx(RenewBottomBanner, {}), userInfo && _jsx(OpenMemberAllReturnNewbieApp, { userInfo: userInfo })] })), _jsx(ReturnRefundWithoutConditionPopup, {})] }) }) }) })));
};
Main.displayName = 'Main';
