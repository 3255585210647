import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUserInfo } from '../../../../../../../../service/platform/useUserInfo';
import { getBtnStyle } from '../../hooks/useBtnText';
import { BaseTaskItem } from '../BaseTaskItem';
import { TaskBtn } from '../TaskBtn';
import styles from './styles.css';
const Badge = () => {
    return _jsx("span", Object.assign({ className: styles.badge }, { children: "\u5DE8\u989D\u8865\u8D34" }));
};
Badge.displayName = 'Badge';
export const RenewTaskItem = ({ task }) => {
    var _a, _b, _c, _d, _e, _f;
    const isFirstRenew = (_b = (_a = useUserInfo()) === null || _a === void 0 ? void 0 : _a.membershipInfo) === null || _b === void 0 ? void 0 : _b.nextPayIsFirstRenewal;
    const renewalTimes = ((_d = (_c = useUserInfo()) === null || _c === void 0 ? void 0 : _c.membershipInfo) === null || _d === void 0 ? void 0 : _d.renewalTimes) || 0;
    const maxRenewalTimesAllowed = ((_f = (_e = useUserInfo()) === null || _e === void 0 ? void 0 : _e.membershipInfo) === null || _f === void 0 ? void 0 : _f.maxRenewalTimesAllowed) || 0;
    // 是否达到最大续费次数
    const hasLimit = renewalTimes >= maxRenewalTimesAllowed;
    const btnUIStyle = getBtnStyle();
    return (_jsxs(BaseTaskItem, Object.assign({ task: task }, { children: [_jsxs("div", Object.assign({ className: styles.taskTitleCt }, { children: [isFirstRenew ? (_jsx("span", Object.assign({ className: styles.titleTxt }, { children: "\u9996\u6B21\u7EED\u8D39\u6781\u7701\u5361" }))) : (_jsx("span", Object.assign({ className: styles.titleTxt }, { children: "\u7EED\u8D39\u6781\u7701\u5361" }))), _jsx(Badge, {})] })), _jsx(TaskBtn, Object.assign({ classname: hasLimit ? btnUIStyle.givenBtn : btnUIStyle.redBtn, btnText: '立即续费', task: task }, { children: hasLimit && _jsxs("span", Object.assign({ className: styles.limitText }, { children: ["\u9650", maxRenewalTimesAllowed, "\u6B21"] })) }))] })));
};
RenewTaskItem.displayName = 'RenewTaskItem';
