import { jsx as _jsx } from "react/jsx-runtime";
// 状态栏
import cx from 'classnames';
import { judgeBigScreen } from '../../service/browser';
import styles from './styles.css';
export const StatusBar = ({ bgColor = 'transparent', }) => {
    return (_jsx("div", Object.assign({ className: cx(styles.container, {
            [styles.isBigScreen]: judgeBigScreen(),
        }), style: {
            backgroundColor: bgColor,
        } }, { children: "\u00A0" })));
};
StatusBar.displayName = 'StatusBar';
