import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TaskStatus } from '@shm/interface/lib/quest/model';
import { webpImage } from '../../../../../../../../service/images';
import { getBtnStyle } from '../../hooks/useBtnText';
import { BaseTaskItem } from '../BaseTaskItem';
import { TaskBtn } from '../TaskBtn';
import redDescImg1 from './assets/red/desc-1.png';
import redDescImg2 from './assets/red/desc-2.png';
import styles from './styles.css';
const DescTexts = ({ status }) => {
    return (_jsxs("div", Object.assign({ className: styles.descTxtCt }, { children: [status !== TaskStatus.TASK_STATUS_REWARD_GIVEN && (_jsx("img", { className: styles.descTxt1, src: webpImage(redDescImg1), alt: "" })), _jsx("img", { className: styles.descTxt2, src: webpImage(redDescImg2), alt: "" })] })));
};
DescTexts.displayName = 'DescTexts';
export const ShoppingTeachTaskItem = ({ task }) => {
    const canGain = (task === null || task === void 0 ? void 0 : task.taskStatus) === TaskStatus.TASK_STATUS_COMPLETED;
    const btnUIStyle = getBtnStyle();
    return (_jsxs(BaseTaskItem, Object.assign({ task: task }, { children: [_jsx(DescTexts, { status: task.taskStatus }), _jsx(TaskBtn, { classname: canGain ? btnUIStyle.yellowBtn : btnUIStyle.redBtn, btnText: canGain ? '领取奖励' : '查看教程', task: task })] })));
};
ShoppingTeachTaskItem.displayName = 'ShoppingTeachTaskItem';
