import { jsx as _jsx } from "react/jsx-runtime";
import { GetSaveMoneyCardAccountInfo } from '@shm/interface/lib/trade/app/service';
import { createContext, useCallback, useState } from 'react';
import { useDidFocus } from '../../../../../../service/useDidFocus';
export const ExtraCashbackContext = createContext({});
export const ExtraCashbackStore = ({ children }) => {
    const [assetsInfo, setAssetsInfo] = useState({});
    const [numberOfUnusedCoupons, setNumberOfUnusedCoupons] = useState(0);
    const fetchAccountInfo = useCallback(() => {
        GetSaveMoneyCardAccountInfo({}).then((res) => {
            const info = {};
            res.assets.forEach((item) => {
                info[item.type] = item;
            });
            setAssetsInfo(info);
            setNumberOfUnusedCoupons(res.numberOfUnusedCoupons);
        });
    }, []);
    useDidFocus(fetchAccountInfo, true);
    return (_jsx(ExtraCashbackContext.Provider, Object.assign({ value: { assetsInfo, numberOfUnusedCoupons } }, { children: children })));
};
ExtraCashbackStore.displayName = 'ExtraCashbackStore';
