import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// 走马灯
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Swipeable } from '../Swipeable';
import styles from './styles.css';
export const Slide = ({ children, onSlide, current, perWidth, left, right, isAnim = true, }) => {
    // 当前索引
    const [currentIndex, setCurrentIndex] = useState(-1);
    // 偏移
    const [currentX, setCurrentX] = useState(0);
    // 最后的偏移
    const lastXRef = useRef(0);
    // 设置指定索引
    const onChangeIndex = useCallback((n) => {
        if (n < 0) {
            setCurrentX(lastXRef.current);
            return;
        }
        if (n > children.length - 1) {
            setCurrentX(lastXRef.current);
            return;
        }
        setCurrentIndex(n);
    }, [children.length]);
    // 滑动中
    const onSwiping = useCallback((e) => {
        setCurrentX(lastXRef.current - (e.deltaX || 0));
    }, []);
    // 左滑动
    const onSwipedLeft = useCallback((e) => {
        if (e.deltaX < 20) {
            setCurrentX(lastXRef.current);
        }
        else {
            onChangeIndex(currentIndex + 1);
        }
    }, [currentIndex, onChangeIndex]);
    // 右滑动
    const onSwipedRight = useCallback((e) => {
        if (e.deltaX > -20) {
            setCurrentX(lastXRef.current);
        }
        else {
            onChangeIndex(currentIndex - 1);
        }
    }, [currentIndex, onChangeIndex]);
    // 左点击
    const onClickLeft = useCallback(() => {
        onChangeIndex(currentIndex - 1);
    }, [currentIndex, onChangeIndex]);
    // 右点击
    const onClickRight = useCallback(() => {
        onChangeIndex(currentIndex + 1);
    }, [currentIndex, onChangeIndex]);
    // 初始化索引
    useEffect(() => {
        onChangeIndex(current);
    }, [current, onChangeIndex]);
    // 索引变化的时候，发生位移
    useEffect(() => {
        if (currentIndex === -1) {
            return;
        }
        const x = 0 - perWidth * currentIndex;
        setCurrentX(x);
        lastXRef.current = x;
        if (onSlide) {
            onSlide(currentIndex);
        }
    }, [currentIndex, onSlide, perWidth]);
    return (_jsxs("div", Object.assign({ className: styles.slideMain }, { children: [_jsx(Swipeable, Object.assign({ onSwiping: onSwiping, onSwipedLeft: onSwipedLeft, onSwipedRight: onSwipedRight }, { children: _jsx("div", Object.assign({ className: classNames(styles.slideList, {
                        [styles.slideListAnim]: isAnim,
                    }), style: {
                        transform: `translateX(${currentX}px)`,
                    } }, { children: children })) })), left ? (_jsx("div", Object.assign({ onClick: onClickLeft, "aria-hidden": true }, { children: left }))) : null, right ? (_jsx("div", Object.assign({ onClick: onClickRight, "aria-hidden": true }, { children: right }))) : null] })));
};
Slide.displayName = 'Slide';
